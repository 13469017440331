import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
  Grid,
} from 'semantic-ui-react';
import {
  Padding,
  H1,
  H3Light,
  H3,
  P,
  H1AboutSmall,
  PAboutDescriptions,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  background: #007c91 !important;
  width: 350px;
  font-family: Poppins !important;
  font-size: 22px !important;
`;

export default (props) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const handleLetsGoClick = () => {
    setOpen(true);
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <div
          style={{
            paddingTop: '123px',
            maxWidth: '920px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall style={{ textAlign: 'center', marginBottom: '20px' }}>
            What is the National Diabetes Prevention Program?
          </H1AboutSmall>
          <PAboutDescriptions style={{ textAlign: 'center' }}>
            The National DPP lifestyle change program is a year-long program
            where participants learn about healthy eating, physical activity,
            and stress management. The National DPP was created by the CDC and
            is proven to help participants lower their chances of developing
            type 2 diabetes - by more than half!{' '}
          </PAboutDescriptions>
        </div>
        <Grid
          columns={2}
          stackable
          divided
          inverted
          style={{ margin: '0', paddingTop: '106px' }}>
          <Grid.Row style={{ padding: '0' }}>
            <Grid.Column
              style={{
                textAlign: 'right',
                paddingRight: '16px',
                marginLeft: 'auto',
              }}>
              <iframe
                width="539"
                height="299"
                src="https://www.youtube.com/embed/k_XoHSIG20U"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label="Imagine: You and the National Diabetes Prevention Program video"></iframe>
            </Grid.Column>
            <Grid.Column
              style={{
                textAlign: 'left',
                paddingLeft: '16px',
                marginRight: 'auto',
              }}>
              <iframe
                width="539"
                height="299"
                src="https://www.youtube.com/embed/w0NDVI4M_Bs"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label="Sneak Peek Into the Lifestyle Change Program video"></iframe>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '140px',
            height: '4px',
            width: '345px',
            backgroundColor: '#95C1D1',
          }}></div>
      </Responsive>{' '}
      <Responsive {...Responsive.onlyTablet}>
        <div
          style={{
            paddingTop: '66px',
            maxWidth: '689px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall
            style={{
              fontSize: '26px',
              textAlign: 'center',
              marginBottom: '20px',
            }}>
            What is the National Diabetes Prevention Program?
          </H1AboutSmall>
          <PAboutDescriptions style={{ fontSize: '18px', textAlign: 'center' }}>
            The National DPP lifestyle change program is a year-long program
            where participants learn about healthy eating, physical activity,
            and stress management. The National DPP was created by the CDC and
            is proven to help participants lower their chances of developing
            type 2 diabetes - by more than half!{' '}
          </PAboutDescriptions>
        </div>
        <Grid
          columns={2}
          stackable
          divided
          inverted
          style={{ margin: '0', paddingTop: '63px' }}>
          <Grid.Row style={{ padding: '0' }}>
            <Grid.Column
              style={{
                textAlign: 'right',
                paddingRight: '15px',
                marginLeft: 'auto',
              }}>
              <iframe
                width="331"
                height="184"
                src="https://www.youtube.com/embed/k_XoHSIG20U"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label="Imagine: You and the National Diabetes Prevention Program video"></iframe>
            </Grid.Column>
            <Grid.Column
              style={{
                textAlign: 'left',
                paddingLeft: '15px',
                marginRight: 'auto',
              }}>
              <iframe
                width="331"
                height="184"
                src="https://www.youtube.com/embed/w0NDVI4M_Bs"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label="Sneak Peek Into the Lifestyle Change Program video"></iframe>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '116px',
            height: '4px',
            width: '345px',
            backgroundColor: '#95C1D1',
          }}></div>
      </Responsive>
      {/* mobile responsive code below */}
      <Responsive {...Responsive.onlyMobile}>
        <div
          style={{
            paddingTop: '94px',
            maxWidth: '343px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall
            style={{
              textAlign: 'center',
              marginBottom: '34px',
              fontSize: '24px',
              letterSpacing: ' 0.35px',
              lineHeight: '32px',
            }}>
            What is the National Diabetes Prevention Program?
          </H1AboutSmall>
          <PAboutDescriptions
            style={{
              textAlign: 'center',
              fontSize: '18px',
              letterSpacing: '0.26px',
              lineHeight: '24px',
            }}>
            The National DPP lifestyle change program is a year-long program
            where participants learn about healthy eating, physical activity,
            and stress management. The National DPP was created by the CDC and
            is proven to help participants lower their chances of developing
            type 2 diabetes - by more than half!{' '}
          </PAboutDescriptions>
        </div>
        <div
          style={{
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingTop: '85px',
          }}>
          <iframe
            width="344"
            height="191"
            src="https://www.youtube.com/embed/k_XoHSIG20U"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            aria-label="Imagine: You and the National Diabetes Prevention Program video"></iframe>
        </div>
        <div
          style={{
            paddingTop: '39px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}>
          <iframe
            width="344"
            height="191"
            src="https://www.youtube.com/embed/w0NDVI4M_Bs"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            aria-label="Sneak Peek Into the Lifestyle Change Program video"></iframe>
        </div>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '108px',
            height: '3px',
            width: '181px',
            backgroundColor: '#95C1D1',
          }}></div>
      </Responsive>
    </Container>
  );
};
