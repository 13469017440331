import React from 'react';
import { Grid, List, Image, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Padding } from './Styles';
// import Icon1 from '../../../assets/icon1.svg';
// import Icon2 from '../../../assets/icon2.svg';

const Container = styled.div`
  width: 100%;
  display: grid;
  background-color: #333;
  color: #fff;
  font-size: 13px;
`;

const styles = {
  item: {
    color: '#fff',
  },
};

export default (props) => {
  return (
    <Container>
      <Padding>
        <Grid inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <List relaxed inverted>
                <List.Item>
                  <List.Content>
                    <List.Header style={styles.item}>
                      HAVE QUESTIONS?
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/cdc-info/index.html"
                    style={styles.item}>
                    <Icon
                      name="desktop"
                      size="large"
                      style={styles.item}
                      aria-label="cdc info"
                      title="cdc info"></Icon>{' '}
                    Visit CDC-INFO
                  </a>
                </List.Item>
                <List.Item style={styles.item}>
                  <Icon
                    flipped="horizontally"
                    name="phone"
                    size="large"
                    style={styles.item}
                    aria-label="phone"
                    title="phone"></Icon>{' '}
                  Call 800-232-4636
                </List.Item>
                <List.Item>
                  <a
                    href="https://wwwn.cdc.gov/dcs/contactus/form"
                    style={styles.item}
                    aria-label="mail"
                    title="mail">
                    <Icon name="mail" size="large" style={styles.item}></Icon>{' '}
                    Email CDC-INFO
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/cdc-info/index.html"
                    style={styles.item}>
                    <Icon
                      name="clock outline"
                      size="large"
                      style={styles.item}
                      aria-label="Open 24/7"
                      title="Open 24/7"></Icon>{' '}
                    Open 24/7
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List relaxed inverted>
                <List.Item>
                  <List.Content>
                    <List.Header style={styles.item}>
                      CDC INFORMATION
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/about/default.htm"
                    style={styles.item}>
                    About CDC
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://jobs.cdc.gov/" style={styles.item}>
                    Jobs
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://www.cdc.gov/funding" style={styles.item}>
                    Funding
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/Other/policies.html"
                    style={styles.item}>
                    Policies
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/other/plugins/index.html"
                    style={styles.item}>
                    File Viewers & Players
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List relaxed inverted>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/other/privacy.html"
                    style={styles.item}>
                    Privacy
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://www.cdc.gov/od/foia" style={styles.item}>
                    FOIA
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/eeo/nofearact/index.htm"
                    style={styles.item}>
                    No Fear Act
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://oig.hhs.gov/" style={styles.item}>
                    OIG
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/other/nondiscrimination.html"
                    style={styles.item}>
                    Nondiscrimination
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://www.cdc.gov/contact/accessibility.html"
                    style={styles.item}>
                    Accessibility
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={5}>
              <List inverted>
                <List.Item>
                  <List.Content>
                    <List.Header style={styles.item}>
                      CONNECT WITH CDC
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List horizontal inverted>
                    <List.Item>
                      <a
                        href="https://www.facebook.com/CDC"
                        aria-label="facebook"
                        title="facebook">
                        <Icon name="facebook" size="large" style={styles.item}>
                          <span hidden>facebook</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://www.instagram.com/CDCgov/"
                        aria-label="instagram"
                        title="instagram">
                        <Icon name="instagram" size="large" style={styles.item}>
                          <span hidden>instagram</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://twitter.com/CDCgov"
                        aria-label="twitter"
                        title="twitter">
                        <Icon name="twitter" size="large" style={styles.item}>
                          <span hidden>twitter</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention"
                        aria-label="linkedin"
                        title="linkedin">
                        <Icon name="linkedin" size="large" style={styles.item}>
                          <span hidden>linkedin</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://www.snapchat.com/add/cdcgov"
                        aria-label="snapchat"
                        title="snapchat">
                        <Icon name="snapchat" size="large" style={styles.item}>
                          <span hidden>snapchat</span>
                        </Icon>
                      </a>
                    </List.Item>
                  </List>
                </List.Item>
                <List.Item>
                  <List horizontal inverted>
                    <List.Item>
                      <a
                        href="https://www.youtube.com/user/CDCstreamingHealth"
                        aria-label="youtube"
                        title="youtube">
                        <Icon name="youtube" size="large" style={styles.item}>
                          <span hidden>youtube</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://tools.cdc.gov/medialibrary/index.aspx#/sharecontent/https://www.cdc.gov/index.htm"
                        aria-label="tools.cdc.gov"
                        title="tools.cdc.gov">
                        <Icon size="large" style={styles.item}>
                          <Image src="assets/icon1.svg" alt=""></Image>
                          <span hidden>tools.cdc.gov</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://www.cdc.gov/cdctv"
                        aria-label="cdc tv"
                        title="cdc tv">
                        <Icon size="large" style={styles.item}>
                          <Image src="assets/icon2.svg" alt=""></Image>
                          <span hidden>cdc tv</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://tools.cdc.gov/podcasts/rss.asp"
                        aria-label="rss"
                        title="rss">
                        <Icon name="rss" size="large" style={styles.item}>
                          <span hidden>rss</span>
                        </Icon>
                      </a>
                    </List.Item>
                    <List.Item>
                      <a
                        href="https://wwwn.cdc.gov/dcs/RequestForm.aspx"
                        aria-label="mail"
                        title="mail">
                        <Icon name="mail" size="large" style={styles.item}>
                          <span hidden>mail</span>
                        </Icon>
                      </a>
                    </List.Item>
                  </List>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Padding>
    </Container>
  );
};
