import React, { useState } from "react";
import styled from "styled-components";
import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
  Grid,
} from "semantic-ui-react";
import {
  Padding,
  H1,
  H1AboutSmall,
  H3Light,
  H3,
  P,
  PAboutDescriptions,
} from "./Styles";
import AddendumQuestions from "../AddendumQuestions";

// import PrediabetesJPG from "../../../assets/OneInThreePrediabetes.png";

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  background: #007c91 !important;
  width: 350px;
  font-family: Poppins !important;
  font-size: 22px !important;
`;

export default (props) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const handleLetsGoClick = () => {
    setOpen(true);
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <Grid
          columns={2}
          stackable
          divided
          inverted
          style={{ padding: "auto", paddingTop: "106px" }}
        >
          <Grid.Row style={{ padding: "0" }}>
            <Grid.Column style={{ padding: "0" }}>
              <div
                style={{
                  paddingTop: "55px",
                  maxWidth: "540px",
                  marginLeft: "auto",
                }}
              >
                <H1AboutSmall
                  style={{ textAlign: "left", marginBottom: "20px" }}
                >
                  What is prediabetes?
                </H1AboutSmall>
                <PAboutDescriptions style={{ textAlign: "left" }}>
                  Prediabetes is a health condition caused when blood sugar
                  levels are higher than normal – but not high enough to be
                  considered type 2 diabetes. Without action, prediabetes can
                  lead to type 2 diabetes. The good news is prediabetes can be
                  reversed with small lifestyle changes like adding in daily
                  walks or eating more fruits and vegetables. Path 2 Prevention
                  and the National DPP can help you get going and learn how to
                  reverse prediabetes.{" "}
                </PAboutDescriptions>
              </div>
            </Grid.Column>
            <Grid.Column style={{ padding: "0" }}>
              <div
                style={{
                  height: "381px",
                  width: "382px",
                  marginLeft: "157px",
                  marginRight: "auto",
                }}
              >
                <div
                  style={{
                    left: "33px",
                    zIndex: "1",
                    position: "relative",
                    height: "350px",
                    width: "350px",
                    backgroundColor: "#E3F0F2",
                    boxShadow: "io0 2px 4px 0 rgba(0,0,0,0.11)",
                  }}
                ></div>
                <Image
                  alt="88 million American adults have prediabetes - blood sugar levels are higher than normal but not high enough yet to be classified as type 2 diabetes"
                  size="large"
                  src="assets/OneInThreePrediabetes.png"
                  style={{
                    top: "-329px",
                    zIndex: "2",
                    position: "relative",
                    height: "350px",
                    width: "350px",
                    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
                  }}
                ></Image>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "113px",
            height: "4px",
            width: "345px",
            backgroundColor: "#95C1D1",
          }}
        ></div>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <Grid
          columns={2}
          stackable
          divided
          inverted
          style={{ padding: "auto", paddingTop: "83px" }}
        >
          <Grid.Row style={{ padding: "0" }}>
            <Grid.Column style={{ padding: "0" }}>
              <div
                style={{
                  paddingTop: "0px",
                  maxWidth: "327px",
                  marginLeft: "auto",
                }}
              >
                <H1AboutSmall
                  style={{ textAlign: "left", marginBottom: "20px" }}
                >
                  What is prediabetes?
                </H1AboutSmall>
                <PAboutDescriptions
                  style={{
                    textAlign: "left",
                    fontSize: "18px",
                    letterSpacing: "0.26px",
                    lineHeight: "24px",
                  }}
                >
                  Prediabetes is a health condition caused when blood sugar
                  levels are higher than normal – but not high enough to be
                  considered type 2 diabetes. Without action, prediabetes can
                  lead to type 2 diabetes. The good news is prediabetes can be
                  reversed with small lifestyle changes like adding in daily
                  walks or eating more fruits and vegetables. Path 2 Prevention
                  and the National DPP can help you get going and learn how to
                  reverse prediabetes.{" "}
                </PAboutDescriptions>
              </div>
            </Grid.Column>
            <Grid.Column style={{ padding: "0" }}>
              <div
                style={{
                  height: "284px",
                  width: "283px",
                  marginLeft: "75px",
                  marginRight: "auto",
                  paddingTop: "25px",
                }}
              >
                <div
                  style={{
                    left: "25px",
                    zIndex: "1",
                    position: "relative",
                    height: "259px",
                    width: "253px",
                    backgroundColor: "#E3F0F2",
                    boxShadow: "io0 2px 4px 0 rgba(0,0,0,0.11)",
                  }}
                ></div>
                <Image
                  alt="88 million American adults have prediabetes - blood sugar levels are higher than normal but not high enough yet to be classified as type 2 diabetes"
                  size="large"
                  src="assets/OneInThreePrediabetes.png"
                  style={{
                    top: "-240px",
                    zIndex: "2",
                    position: "relative",
                    height: "260px",
                    width: "260px",
                    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
                  }}
                ></Image>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "70px",
            height: "4px",
            width: "326px",
            backgroundColor: "#95C1D1",
          }}
        ></div>
      </Responsive>
      {/* Mobile responsive code below */}
      <Responsive {...Responsive.onlyMobile}>
        <div
          style={{
            paddingTop: "67px",
            maxWidth: "343px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <H1AboutSmall>What is prediabetes?</H1AboutSmall>
          <PAboutDescriptions
            style={{
              textAlign: "center",
              fontSize: "18px",
              letterSpacing: "0.26px",
              lineHeight: "24px",
            }}
          >
            Prediabetes is a health condition caused when blood sugar levels are
            higher than normal – but not high enough to be considered type 2
            diabetes. Without action, prediabetes can lead to type 2 diabetes.
            The good news is prediabetes can be reversed with small lifestyle
            changes like adding in daily walks or eating more fruits and
            vegetables. Path 2 Prevention and the National DPP can help you get
            going and learn how to reverse prediabetes.{" "}
          </PAboutDescriptions>
        </div>

        <div
          style={{
            marginTop: "95px",
            height: "284px",
            width: "283px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              left: "33px",
              zIndex: "1",
              position: "relative",
              height: "259px",
              width: "253px",
              backgroundColor: "#E3F0F2",
              boxShadow: "io0 2px 4px 0 rgba(0,0,0,0.11)",
            }}
          ></div>
          <Image
            alt="88 million American adults have prediabetes - blood sugar levels are higher than normal but not high enough yet to be classified as type 2 diabetes"
            size="large"
            src="assets/OneInThreePrediabetes.png"
            style={{
              top: "-236px",
              zIndex: "2",
              position: "relative",
              height: "260px",
              width: "260px",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
            }}
          ></Image>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "121px",
            height: "3px",
            width: "181px",
            backgroundColor: "#95C1D1",
          }}
        ></div>
      </Responsive>
    </Container>
  );
};
