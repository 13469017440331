import React, { Fragment } from "react";
import styled from "styled-components";

export const Padding = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
`;
export const PaddingBackArrow = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #007c91;
  padding: 10px 0 1px 17px;
  text-align: left;
  @media only screen and (min-width: 768px) {
    padding: 19px 0 1px 39px;
  }
  @media only screen and (min-width: 992px) {
    padding: 20px 0 2px 46px;
  }
`;

export const H1 = styled.h1`
  font-size: calc(28px + (56 - 28) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  margin: 0px;
  font-family: Poppins !important;
`;
export const H1About = styled.h1`
  font-family: Poppins !important;
  color: #fff;

  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 30px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
  @media only screen and (min-width: 992px) {
    letter-spacing: 0.69px;
    line-height: 62px;
    font-size: 48px;
    font-weight: 600;
    margin: 0px;
  }
`;
export const H1AboutSmall = styled.h1`
  color: #333333;
  font-family: Poppins;
  textAlign: center,
  font-size: 24px,
  margin-bottom: 18px,
  font-weight: 600;
  letter-spacing: 0.46px;
  line-height: 50px;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 32px;
   

  }
`;
export const LargeButtonText = styled.div`
  font-size: 22px;
  font-weight: 500;
  font-family: Poppins !important;
`;
export const PAboutDescriptions = styled.p`
  color: #000000;
  font-family: "Nunito";
  font-size: 22px;
  letter-spacing: 0.32px;
  line-height: 30px;
  text-align: center;
  marginLeft: 'auto',
  marginRight: 'auto',

`;
export const H2 = styled.h2`
  font-size: calc(22px + (32 - 22) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  font-family: Poppins !important;
`;
export const H3 = styled.h3`
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  font-family: Poppins !important;
`;
export const H3Light = styled.h3`
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 100;
  font-family: Nunito !important;
`;
export const P = styled.p`
  color: #ffffff;
  font-weight: 500;
  font-family: "Nunito" !important;
  padding: 31px 5px 0 5px;
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 25px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    padding-top: 13px;

    max-width: 688px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    paddingtop: 19px;

    maxwidth: 1110px;
    letter-spacing: 0.32px;
    line-height: 30px;
    text-align: center;
  }
`;
export const H4 = styled.h4`
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  font-family: Poppins !important;
`;
export const Link = styled.a`
  color: black;
`;
