import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  AP2Content,
  EnterNameText,
  ItalicSubtext,
  AP4ButtonContainer,
  ClickableCardFull,
  ClickableCardFullSelected,
  InnerButtonDiv,
  InputTextArea,
  CardTitle,
  CardFullTopInfo,
  CardFullSubtext,
  TipText,
  SucessTextBox,
  SuccessMessage,
  SuccessTitle,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
const CheckIcon = styled(Image)`
  width: 24px;
  height: 24px;
  display: inline !important;
  margin-top: 6px !important;
`;
const IconImage = styled(Image)`
  width: 30px !important;
  height: 30px !important;
  display: inline !important;
  margin-left: auto;
  margin-right: auto;
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  line-height: 0 !important;
  margin: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

class ActionPlan4 extends Component {
  constructor(props) {
    super(props);
    //this.handleExpandClick = this.handleExpandClick.bind(this);
    //this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.state = {
      error: false,
      isExpanded: false,
      selectedItems: [],
      otherText: '',
      somethingSelected: false,
    };
    if (this.props.store.AP4Items) {
      this.props.store.AP4Items.forEach((e) => {
        if (e.isSelected) {
          this.state.selectedItems.push(e.indexNum);
        }
      });
    }
    window.scrollTo(0, 0);
  }
  render() {
    const handleButtonClick = (parent) => {
      //add in code to push other
      let blank = '';

      if (this.props.store.AP4OtherText == undefined) {
        this.props.store.set('AP4OtherText', blank);
      }
      let selectedItems = parent.state.selectedItems;

      items.forEach((e) => {
        var checked = false;
        selectedItems.forEach((i) => {
          if (e.indexNum == i) {
            checked = true;
          }
        });
        e.isSelected = checked;
      });
      if (
        selectedItems.length > 0 ||
        parent.props.store.AP4OtherText.length > 0
      ) {
        parent.props.store.set('AP4Items', items);
        //parent.props.store.set("AP4OtherText", parent.state.otherText);
        parent.props.store.set('errorAP4', false);
        parent.props.store.set('currentActionPlan', '5');
      } else {
        parent.props.store.set('errorAP4', true);
      }
    };
    const handleBackClick = (parent) => {
      parent.props.store.set('currentActionPlan', '3'); 
    };
    const handleInputText = (val) => {
      if (val.target.value.length > 0) {
        this.state.somethingSelected = true;
        this.props.store.set('errorAP4', false);
      }
      this.props.store.set('AP4OtherText', val.target.value);
      //  setOther(val.target.value);
    };

    const handleCardClick = (item, index, parent) => {
      parent.props.store.set('errorAP4', false);

      let selectedItems = parent.state.selectedItems;
      if (selectedItems.indexOf(item.indexNum) >= 0) {
        selectedItems.splice(selectedItems.indexOf(item.indexNum), 1);
      } else {
        selectedItems.push(item.indexNum);
      }
      if (selectedItems.length > 0) {
        parent.state.somethingSelected = true;
      } else {
        parent.state.somethingSelected = false;
      }

      parent.setState(selectedItems);
    };
    let items = [
      {
        text: 'Caregiver duties (childcare or other) ',
        subtext:
          'Being a caregiver is an important role! Could someone fill in for you while you attend class?',
        iconSrc: 'assets/step4_caregiver.svg',
        isSelected: false,
        indexNum: 1,
      },
      {
        text: 'Family meals',
        subtext:
          'Some participants prepare meals beforehand to be able to attend classes. Is there a meal you could cook in advance for your family?',
        iconSrc: 'assets/step4_familymeals.svg',
        isSelected: false,
        indexNum: 2,
      },
      {
        text: 'Schedule changes',
        subtext:
          'Pick a class that works for your schedule now! Think about what plans you can put in place if your schedule changes. Would an online class work better for you?',
        iconSrc: 'assets/step4_schedulechanges.svg',
        isSelected: false,
        indexNum: 3,
      },
      {
        text: 'Travel plans',
        subtext:
          'Have an upcoming vacation? Find a class that fits your plans! You can select a class meeting day that does not interfere with your weekend getaways. When you contact a program, mention your plans and discuss the best option for you.',
        iconSrc: 'assets/step4_travelplans.svg',
        isSelected: false,
        indexNum: 4,
      },
      {
        text: 'Transportation',
        subtext:
          'Concerned about how to get to class? Consider looking at your city transportation options or asking friends and family to help you get to class. Or, ask the program if they have transportation options.',
        iconSrc: 'assets/step4_transportation.svg',
        isSelected: false,
        indexNum: 5,
      },
      {
        text: 'Pet care',
        subtext:
          'Have a pet at home? Check to see if a neighbor could watch your pet during class.',
        iconSrc: 'assets/step4_pet care.svg',
        isSelected: false,
        indexNum: 6,
      },
      {
        text: 'Prior commitments ',
        subtext:
          'Can someone fill in for you during this time? Could you reschedule your previous plans?',
        iconSrc: 'assets/step4_prior commitments.svg',
        isSelected: false,
        indexNum: 7,
      },
    ];

    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>4/7</PageNumber>
                    <PageTitle>Identifying Barriers</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                The path to change is not always simple. Take some time to think
                about the challenges you may facing along the way. Planning for
                them now can make facing them in real life much easier.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  To be able to enroll in the program and attend classes, I need
                  to plan for:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                          <CardFullSubtext>{value.subtext}</CardFullSubtext>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          >
                            {' '}
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 4 other text"
                      type="text"
                      maxLength="150"
                      value={this.props.store.AP4OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Remember, everyone faces obstacles.
                    </SuccessTitle>
                    <TipText>
                      Consider the obstacle(s) you face and ask yourself these
                      questions to help find a solution. Who can help you
                      overcome these obstacles? Can someone fill in for you?
                      What has worked for you in the past? What can you do to
                      get ready for this?
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}
              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Class
              </CustomButton>
              {this.props.store.errorAP4 ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose a reason above before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>4/7</PageNumber>
                    <PageTitle>Identifying Barriers</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                The path to change is not always simple. Take some time to think
                about the challenges you may facing along the way. Planning for
                them now can make facing them in real life much easier.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  To be able to enroll in the program and attend classes, I need
                  to plan for:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                          <CardFullSubtext>{value.subtext}</CardFullSubtext>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          >
                            {' '}
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 4 other text"
                      type="text"
                      maxLength="150"
                      value={this.props.store.AP4OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Remember, everyone faces obstacles.
                    </SuccessTitle>
                    <TipText>
                      Consider the obstacle(s) you face and ask yourself these
                      questions to help find a solution. Who can help you
                      overcome these obstacles? Can someone fill in for you?
                      What has worked for you in the past? What can you do to
                      get ready for this?
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}
              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Class
              </CustomButton>
              {this.props.store.errorAP4 ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose a reason above before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '40%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>4/7</PageNumber>
                    <PageTitle>Identifying Barriers</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                The path to change is not always simple. Take some time to think
                about the challenges you may facing along the way. Planning for
                them now can make facing them in real life much easier.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  To be able to enroll in the program and attend classes, I need
                  to plan for:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                          <CardFullSubtext>{value.subtext}</CardFullSubtext>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <CardTitle>{value.text}</CardTitle>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 4 other text"
                      type="text"
                      maxLength="150"
                      value={this.props.store.AP4OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Remember, everyone faces obstacles.
                    </SuccessTitle>
                    <TipText>
                      Consider the obstacle(s) you face and ask yourself these
                      questions to help find a solution. Who can help you
                      overcome these obstacles? Can someone fill in for you?
                      What has worked for you in the past? What can you do to
                      get ready for this?
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}
              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Class
              </CustomButton>
              {this.props.store.errorAP4 ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose a reason above before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
      </Container>
    );
  }
}

export default withStore(ActionPlan4);
