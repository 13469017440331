import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Image, Responsive } from 'semantic-ui-react';
import { BodyPadding, H2, H4, Text, SmallButtonText } from './Styles';
// import Step1 from '../../../assets/step1.svg';
// import Step2 from '../../../assets/step2.svg';
// import Step3 from '../../../assets/step3.svg';
// import Step4 from '../../../assets/step4.svg';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  background-color: #e3f0f2 !important;
  height: 47px;
  width: 174px;
  padding: 1px !important;
  &:hover {
    background-color: #badde2 !important;
  }
`;
export default (props) => {
  const history = useHistory();
  const handleBeginPathClick = () => {
    history.push('/beginpath');
  };
  const handleLearnMoreClick = () => {
    history.push('/learnmore');
  };
  const handleStartPlanClick = () => {
    history.push('/startplan');
  };
  const handleFindAProgramClick = () => {
    history.push('/findaprogram');
  };
  return (
    <Container role="main" aria-label="How it works Container">
      <BodyPadding>
        <H2>Here's how it works.</H2>
        <Text
          style={{
            maxWidth: '720px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          Click into a section below to find videos, interactive games, and more
          to start on your Path 2 Prevention.
        </Text>
        <br />
        <br />
        <br />
        <Grid stackable doubling columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Image
                src="assets/step1.svg"
                centered
                style={{ height: '100px' }}
                alt=""></Image>
              <br />
              <H4>What is The Path?</H4>
              <br />
              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <CustomButton onClick={handleBeginPathClick}>
                  <SmallButtonText>Begin Path</SmallButtonText>
                </CustomButton>
              </Responsive>
            </Grid.Column>
            <Grid.Column>
              <Image
                src="assets/step2.svg"
                centered
                style={{ height: '100px' }}
                alt=""></Image>
              <br />
              <H4>Why Does it Matter?</H4>
              <br />
              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <CustomButton onClick={handleLearnMoreClick}>
                  <SmallButtonText>Get the Facts</SmallButtonText>
                </CustomButton>
              </Responsive>
            </Grid.Column>
            <Grid.Column>
              <Image
                src="assets/step3.svg"
                centered
                style={{ height: '100px' }}
                alt=""></Image>
              <br />
              <H4>Plan My Path</H4>
              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <br />
                <CustomButton onClick={handleStartPlanClick}>
                  <SmallButtonText>Start Plan</SmallButtonText>
                </CustomButton>
              </Responsive>
            </Grid.Column>
            <Grid.Column>
              <Image
                src="assets/step4.svg"
                centered
                style={{ height: '100px' }}
                alt=""></Image>
              <br />
              <H4>Find a Program</H4>
              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <br />
                <CustomButton onClick={handleFindAProgramClick}>
                  <SmallButtonText>Find a Program</SmallButtonText>
                </CustomButton>
              </Responsive>
            </Grid.Column>
          </Grid.Row>
          <Responsive as={Grid.Row} minWidth={Responsive.onlyComputer.minWidth}>
            <Grid.Column>
              <CustomButton onClick={handleBeginPathClick}>
                <SmallButtonText>Begin Path</SmallButtonText>
              </CustomButton>
            </Grid.Column>
            <Grid.Column>
              <CustomButton onClick={handleLearnMoreClick}>
                <SmallButtonText>Get the Facts</SmallButtonText>
              </CustomButton>
            </Grid.Column>
            <Grid.Column>
              <CustomButton onClick={handleStartPlanClick}>
                <SmallButtonText>Start Plan</SmallButtonText>
              </CustomButton>
            </Grid.Column>
            <Grid.Column>
              <CustomButton onClick={handleFindAProgramClick}>
                <SmallButtonText>Find a Program</SmallButtonText>
              </CustomButton>
            </Grid.Column>
          </Responsive>
        </Grid>
      </BodyPadding>
    </Container>
  );
};
