import React, { Fragment, useEffect } from 'react';
import { H1 } from '../Styles/Styles';
import NavMenu from './NavMenu';
import BeginPath from './BeginPath';

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: '#F5F5F5', display: 'grid' }}>
      <Fragment>
        <NavMenu location={props.location.pathname}></NavMenu>
        <BeginPath></BeginPath>
      </Fragment>
    </div>
  );
};
