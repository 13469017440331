import React, { Fragment, useEffect } from 'react';
import { H4, SmallText, SmallBoldText } from './Styles';
import { Button, Responsive, Image, Grid } from 'semantic-ui-react';
import { withStore } from '../../store';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 272px;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

export const TealLink = styled.a`
  color: #007c91 !important;
  text-decoration: underline;
  font-family: 'Nunito' !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.26px;
  line-height: 25px;
  &:hover {
    text-decoration: none !important;
  }
`;

export const Percentage = styled.div`
  color: #262525;
  font-family: Poppins !important;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.58px;
  line-height: 60px;
  margin-bottom: 22px;
`;
export const LargeButtonText = styled.div`
  font-family: Poppins !important;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.32px;
  line-height: 33px;
`;
export const Card = styled.div`
  height: 330px;
  border: 1px solid #bed0e1;
  border-radius: 4px;
  background-color: #f2f8fd;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 31px;
`;
const questions = {
  1: {
    question: "Prediabetes can lead to type 2 diabetes, but it's reversible.",
    answer: 'FACT',
    info:
      'Prediabetes can lead to the development of type 2 diabetes. Fortunately, prediabetes can be reversed through healthy eating and increased physical activity. Continue on to learn how the National Diabetes Prevention Program can help you prevent type 2 diabetes!',
  },
  2: {
    question: 'Prediabetes is not a real medical condition.',
    answer: 'MYTH',
    info:
      'Prediabetes is a medical condition that can be diagnosed by a doctor. Prediabetes means your blood sugar is higher than normal, but not high enough to be considered type 2 diabetes. If you have prediabetes, you can take small steps to lower your blood sugar and avoid developing type 2 diabetes. ',
  },
  3: {
    question: 'Dogs can get diabetes.',
    answer: 'FACT',
    info:
      'It may surprise you, but some animals, like dogs, can be diagnosed with diabetes. Diabetes is non-infectious – that means it cannot be transferred from person to person, or from person to dog! ',
  },
  4: {
    question:
      'The only diet for people at risk for type 2 diabetes is to avoid eating red meat, carbs, or added sugars.',
    answer: 'MYTH',
    info:
      'Reducing your risk for type 2 diabetes doesn’t mean giving up all your favorite foods. Preventing type 2 diabetes means making personal lifestyle changes that work for you. Lifestyle change is made up of small choices that contribute to a healthier life – it’s not a drastic change or fad diet.   ',
  },
  5: {
    question:
      'You can lower your chances of developing type 2 diabetes by more than 50% if you lose a small percentage of your body weight and add more physical activity to your weekly routine.  ',
    answer: 'FACT',
    info:
      'If you lose a small percent of your body weight (just 10 to 14 pounds if you weigh 200 pounds) and add ~2.5 hours of physical activity to your routine each week, you can lower your chance of developing type 2 diabetes by more than 50%! You can pick the type of activity that works for you. Whatever gets you active! ',
  },
  6: {
    question:
      ' If you have family members with type 2 diabetes, you will definitely get it too. ',
    answer: 'MYTH',
    info:
      'While your family history can increase your likelihood of type 2 diabetes, it can still be delayed or prevented with lifestyle change, such as small changes to your diet and physical activity. The National DPP lifestyle change program can help you successfully make those small changes and learn more about type 2 diabetes.  ',
  },
  7: {
    question:
      'Diabetes can cause heart disease, kidney failure, loss of vision, and other serious medical conditions.  ',
    answer: 'FACT',
    info:
      'Diabetes can cause some serious health problems like kidney failure, loss of vision, and heart disease. Avoiding those problems is possible—keep on going to learn how! ',
  },
  8: {
    question: 'The National DPP is a diet and nutrition program.',
    answer: 'MYTH',
    info:
      'Program topics cover all different areas of your life, like stress management, getting support from friends and family, healthy eating, and more!  ',
  },
  9: {
    question: 'Having diabetes can increase your medical expenses.',
    answer: 'FACT',
    info:
      'Diabetes can cause health bills to go up to pay for doctors visits, prescription medicine, and more. On average, people with diabetes spend 2.3 times more on medical bills than others.',
  },
  10: {
    question:
      'Even 10 years after the program, National DPP participants are 33% less likely to develop type 2 diabetes than those who did not participate in the program.',
    answer: 'FACT',
    info:
      'The National DPP helps participants add healthy habits that stick and continue to improve their health in the long run.',
  },
};

const Result = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const current_game_score = props.store.current_game_score;
  const handleContinueClick = () => {
    history.push('/startplan');
  };
  return (
    <Fragment>
      <Percentage>{current_game_score}%</Percentage>
      <SmallText style={{ color: '#262525', marginBottom: '25px' }}>
        Thanks for playing! Continue on your path below.
      </SmallText>
      <Image
        src="assets/CongratsIllustration.svg"
        alt=""
        centered
        style={{ marginBottom: '88px' }}></Image>
      <Grid doubling stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TealLink
              href="https://www.cdc.gov/diabetes/prevention/people-at-risk.html"
              target="_blank">
              Learn More About Prediabetes
            </TealLink>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              <CustomButton onClick={handleContinueClick}>
                <H4 style={{ color: 'white' }}>Continue</H4>
              </CustomButton>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
              <CustomButton fluid onClick={handleContinueClick}>
                <H4 style={{ color: 'white' }}>Continue</H4>
              </CustomButton>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <SmallText style={{ color: '#262525' }}>Review the Cards!</SmallText>
      <br />
      <Grid padded>
        <Grid.Row>
          {Object.keys(questions).map((e, i) => {
            // console.log(e);
            // console.log(i);
            return (
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                key={e + i + 'text1'}>
                <Card>
                  <SmallBoldText
                    style={{ color: '#262525', padding: '14.5px' }}>
                    {e}. {questions[e].question}
                  </SmallBoldText>
                  <SmallText style={{ color: '#262525', padding: '14.5px' }}>
                    {questions[e].answer}! {questions[e].info}
                  </SmallText>
                </Card>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default withStore(Result);
