import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import { H5, Text, LargeButtonText } from './Styles';
import Question1 from './Question1';
import Question2 from './Question2';
import Question3 from './Question3';
import { withStore } from '../../store';
import { useHistory } from 'react-router-dom';
import { Container, TealButton, TealLink, Suggestion } from './Styles';

const AddendumContent = (props) => {
  const history = useHistory();
  const answer1 = props.store.answer1;
  const answer2 = props.store.answer2;
  const answer3 = props.store.answer3;
  const currentQuestion = props.store.currentQuestion;
  const error = props.store.error;

  const handleGetStartedClick = () => {
    if (currentQuestion == '1' || !currentQuestion) {
      if (!answer1) {
        props.store.set('error', true);
      } else {
         props.close ? props.close() : '';
        history.push('/findaprogram');
      }
    }
    if (currentQuestion == '2') {
      if (!answer2) {
        props.store.set('error', true);
      }
      if (answer2 == 'Somewhat Likely') {
         props.close ? props.close() : '';
        history.push('/learnmore');
      }
      if (answer2 == 'Not Likely') {
         props.close ? props.close() : '';
        history.push('/beginpath');
      }
    }
    if (currentQuestion == '3') {
      if (!answer3) {
        props.store.set('error', true);
      }
      if (answer3 == 'Yes') {
        props.close ? props.close() : '';
        history.push('/startplan');
      }
      if (answer3 == 'No') {
         props.close ? props.close() : '';
        history.push('/learnmore');
      }
    }
  };

  return (
    <Fragment>
      <Container>
        <Text style={{ color: '#333333' }}>
          Answer a few more questions to receive recommended content, such as
          videos and interactive games, that will kickstart your Path 2
          Preventing diabetes!
        </Text>
      </Container>
      <Container
        style={{
          backgroundColor: '#f2f8fd',
          textAlign: 'left',
        }}>
        {currentQuestion == '1' || !currentQuestion ? <Question1 /> : ''}
        {currentQuestion == '2' ? <Question2 /> : ''}
        {currentQuestion == '3' ? <Question3 /> : ''}
      </Container>
      <Container>
        <Responsive {...Responsive.onlyMobile}>
          <TealButton
            size="massive"
            inverted
            fluid
            onClick={handleGetStartedClick}>
            <LargeButtonText>Get Started!</LargeButtonText>
          </TealButton>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <TealButton
            size="massive"
            inverted
            compact
            onClick={handleGetStartedClick}>
            <LargeButtonText>Get Started!</LargeButtonText>
          </TealButton>
        </Responsive>
        {error ? (
          <H5 style={{ color: '#BF2600' }}>
            Choose an answer above before selecting Get Started!{' '}
          </H5>
        ) : (
          ''
        )}
        {currentQuestion == '1' && answer1 == 'Yes' ? (
          <Suggestion pointing>
            <H5 style={{ color: '#262525' }}>
              Based on your answers, you’re ready to learn more about the
              National Diabetes Prevention Program and how it can teach you
              small tips to sustain your success. Click “Get Started” to find a
              program provider that works for you!
            </H5>
          </Suggestion>
        ) : (
          ''
        )}
        {currentQuestion == '2' && answer2 == 'Not Likely' ? (
          <Suggestion pointing>
            <H5 style={{ color: '#262525' }}>
              Thanks for answering! Get started by watching Lisa’s Story, a
              short video to learn about Lisa’s experience with prediabetes and
              what to do next. Click “Get Started” to move to the video.
            </H5>
          </Suggestion>
        ) : (
          ''
        )}
        {currentQuestion == '2' && answer2 == 'Somewhat Likely' ? (
          <Suggestion pointing>
            <H5 style={{ color: '#262525' }}>
              Thanks for answering! Get started by playing the Myth or Fact game
              to test what you know about prediabetes – and learn something new.
              Click “Get Started” to move to the game.
            </H5>
          </Suggestion>
        ) : (
          ''
        )}
        {currentQuestion == '3' && answer3 == 'Yes' ? (
          <Suggestion pointing>
            <H5 style={{ color: '#262525' }}>
              Thanks for answering! Click “Get Started” to begin building your
              Action Plan for reversing prediabetes.
            </H5>
          </Suggestion>
        ) : (
          ''
        )}
        {currentQuestion == '3' && answer3 == 'No' ? (
          <Suggestion pointing>
            <H5 style={{ color: '#262525' }}>
              Thanks for answering! Get started by playing the Myth or Fact game
              to test what you know about prediabetes – and learn something new.
              Click “Get Started” to move to the game.
            </H5>
          </Suggestion>
        ) : (
          ''
        )}
      </Container>

      <div
        style={{
          borderTop: '1px solid #BDBDBD',
          textAlign: 'center',
          paddingTop: '22px',
          paddingBottom: '37.5px',
        }}>
        <H5 style={{ color: '#007C91 !important' }}>
          Path to Prevention is a resource for people at high risk for
          prediabetes. Unsure if that is you?
        </H5>
        <Text>
          <TealLink href="https://www.cdc.gov/diabetes/risktest/index.html">
            Take the Risk Test to find out.
          </TealLink>
        </Text>
      </div>
    </Fragment>
  );
};

export default withStore(AddendumContent);
