import React, { Component, useState } from 'react';
import styled from 'styled-components';

import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  HorizontalListLi,
  HorizontalListUL,
  MenuOuter,
  NavDivs,
  NavDivSelected,
  SelectedLink,
  SelectedSpan,
  NavSpan,
  NavDivSelectedMobile,
  Overlay,
} from './Styles';
const Container = styled.div`
  width: 100%;
  text-align: center;
`;

class NavMenu extends Component {
  constructor(props) {
    super(props);
    //this.handleExpandClick = this.handleExpandClick.bind(this);
    //this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.state = { isExpanded: false };
  }

  // export default (props) => {

  render() {
    const handleExpandClick = () => {
      this.setState({ isExpanded: true });
    };
    const handleCollapseClick = () => {
      this.setState({ isExpanded: false });
    };

    function CollapsedMenu(props) {
      const location = props.location;

      return (
        <MenuOuter
          style={{ zIndex: '3', position: 'relative', height: 'auto' }}>
          {' '}
          <HorizontalListUL style={{ marginBottom: '0' }}>
            <li>
              <div
                onClick={handleExpandClick}
                onKeyPress={handleExpandClick}
                tabIndex="0"
                style={{ width: '100%' }}>
                {location.indexOf('beginpath') > 0 ? (
                  <NavDivSelectedMobile style={{ width: '100%' }}>
                    <SelectedSpan>What is the path?</SelectedSpan>
                  </NavDivSelectedMobile>
                ) : location.indexOf('learnmore') > 0 ? (
                  <NavDivSelectedMobile style={{ width: '100%' }}>
                    <SelectedSpan>Why does it matter?</SelectedSpan>
                  </NavDivSelectedMobile>
                ) : location.indexOf('startplan') > 0 ? (
                  <NavDivSelectedMobile style={{ width: '100%' }}>
                    <SelectedSpan>Plan my path</SelectedSpan>
                  </NavDivSelectedMobile>
                ) : location.indexOf('findaprogram') > 0 ? (
                  <NavDivSelectedMobile style={{ width: '100%' }}>
                    <SelectedSpan>Find a program</SelectedSpan>
                  </NavDivSelectedMobile>
                ) : (
                  ''
                )}
              </div>
            </li>
          </HorizontalListUL>
        </MenuOuter>
      );
    }
    function ExpandedMenu(props) {
      const location = props.location;
      const history = useHistory();
      const handleBeginPathClick = () => {
        history.push('/beginpath');
      };
      const handleLearnMoreClick = () => {
        history.push('/learnmore');
      };
      const handleStartPlanClick = () => {
        history.push('/startplan');
      };
      const handleFindAProgramClick = () => {
        history.push('/findaprogram');
      };

      return (
        <div>
          <MenuOuter style={{ zIndex: '3', position: 'relative' }}>
            <NavDivs
              style={{ width: '100%', textAlign: 'right' }}
              onKeyPress={handleCollapseClick}
              tabIndex="0">
              <Image
                alt="collapse menu"
                size="small"
                src="assets/Close_Background.svg"
                style={{
                  height: '30px',
                  width: '30px',
                  marginLeft: 'auto',
                  marginTop: '4px',
                  marginRight: '15px',
                }}
                onClick={handleCollapseClick}></Image>
            </NavDivs>

            <HorizontalListUL>
              <li>
                {location.indexOf('beginpath') > 0 ? (
                  <a
                    onClick={handleBeginPathClick}
                    onKeyPress={handleBeginPathClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivSelected style={{ width: '100%' }}>
                      <SelectedSpan>What is the path?</SelectedSpan>
                    </NavDivSelected>
                  </a>
                ) : (
                  <a
                    onClick={handleBeginPathClick}
                    onKeyPress={handleBeginPathClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>What is the path?</NavSpan>
                    </NavDivs>
                  </a>
                )}
              </li>
              <li>
                {location.indexOf('learnmore') > 0 ? (
                  <a
                    onClick={handleLearnMoreClick}
                    onKeyPress={handleLearnMoreClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivSelected style={{ width: '100%' }}>
                      <SelectedSpan>Why does it matter?</SelectedSpan>
                    </NavDivSelected>
                  </a>
                ) : (
                  <a
                    onClick={handleLearnMoreClick}
                    onKeyPress={handleLearnMoreClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>Why does it matter?</NavSpan>
                    </NavDivs>
                  </a>
                )}
              </li>
              <li>
                {location.indexOf('startplan') > 0 ? (
                  <a
                    onClick={handleStartPlanClick}
                    onKeyPress={handleStartPlanClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivSelected style={{ width: '100%' }}>
                      <SelectedSpan>Plan my path</SelectedSpan>
                    </NavDivSelected>
                  </a>
                ) : (
                  <a
                    onClick={handleStartPlanClick}
                    onKeyPress={handleStartPlanClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>Plan my path</NavSpan>
                    </NavDivs>
                  </a>
                )}
              </li>
              <li>
                {location.indexOf('findaprogram') > 0 ? (
                  <a
                    onClick={handleFindAProgramClick}
                    onKeyPress={handleFindAProgramClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivSelected style={{ width: '100%' }}>
                      <SelectedSpan>Find a program</SelectedSpan>
                    </NavDivSelected>
                  </a>
                ) : (
                  <a
                    onClick={handleFindAProgramClick}
                    onKeyPress={handleFindAProgramClick}
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>Find a program</NavSpan>
                    </NavDivs>
                  </a>
                )}
              </li>
            </HorizontalListUL>
          </MenuOuter>
          <Overlay></Overlay>
        </div>
      );
    }
    function ExpandMenuLogic(props) {
      const location = props.location;
      const isExpanded = props.isExpanded;

      if (isExpanded) {
        return <ExpandedMenu location={location} />;
      }
      return <CollapsedMenu location={location} />;
    }
    function DesktopMenuLogic(props) {
      const location = props.location;
      const history = useHistory();
      const handleBeginPathClick = () => {
        history.push('/beginpath');
      };
      const handleLearnMoreClick = () => {
        history.push('/learnmore');
      };
      const handleStartPlanClick = () => {
        history.push('/startplan');
      };
      const handleFindAProgramClick = () => {
        history.push('/findaprogram');
      };

      return (
        <MenuOuter style={{ paddingTop: '17px' }}>
          <HorizontalListUL>
            <HorizontalListLi>
              {location.indexOf('beginpath') > 0 ? (
                <SelectedLink
                  onClick={handleBeginPathClick}
                  onKeyPress={handleBeginPathClick}
                  aria-label="begin path navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivSelected>
                    <SelectedSpan>What is the path?</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleBeginPathClick}
                  onKeyPress={handleBeginPathClick}
                  aria-label="begin path navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivs>
                    <NavSpan>What is the path?</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('learnmore') > 0 ? (
                <SelectedLink
                  onClick={handleLearnMoreClick}
                  onKeyPress={handleLearnMoreClick}
                  aria-label="learn more navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivSelected>
                    <SelectedSpan>Why does it matter?</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleLearnMoreClick}
                  onKeyPress={handleLearnMoreClick}
                  aria-label="learn more navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivs>
                    <NavSpan>Why does it matter?</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('startplan') > 0 ? (
                <SelectedLink
                  onClick={handleStartPlanClick}
                  onKeyPress={handleStartPlanClick}
                  aria-label="start plan navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivSelected>
                    <SelectedSpan>Plan my path</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleStartPlanClick}
                  onKeyPress={handleStartPlanClick}
                  aria-label="start plan navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivs>
                    <NavSpan>Plan my path</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('findaprogram') > 0 ? (
                <a
                  onClick={handleFindAProgramClick}
                  onKeyPress={handleFindAProgramClick}
                  aria-label="find a program navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivSelected>
                    <SelectedSpan>Find a program</SelectedSpan>
                  </NavDivSelected>
                </a>
              ) : (
                <a
                  onClick={handleFindAProgramClick}
                  onKeyPress={handleFindAProgramClick}
                  aria-label="find a program navigation"
                  tabIndex="0"
                  role="link">
                  <NavDivs>
                    <NavSpan>Find a program</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
          </HorizontalListUL>
        </MenuOuter>
      );
    }
    function TabletMenuLogic(props) {
      const location = props.location;
      const history = useHistory();
      const handleBeginPathClick = () => {
        history.push('/beginpath');
      };
      const handleLearnMoreClick = () => {
        history.push('/learnmore');
      };
      const handleStartPlanClick = () => {
        history.push('/startplan');
      };
      const handleFindAProgramClick = () => {
        history.push('/findaprogram');
      };

      return (
        <MenuOuter style={{ paddingTop: '17px' }}>
          <HorizontalListUL>
            <HorizontalListLi>
              {location.indexOf('beginpath') > 0 ? (
                <SelectedLink
                  onClick={handleBeginPathClick}
                  onKeyPress={handleBeginPathClick}
                  tabIndex="0">
                  <NavDivSelected>
                    <SelectedSpan>What is the path?</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleBeginPathClick}
                  onKeyPress={handleBeginPathClick}
                  tabIndex="0">
                  <NavDivs>
                    <NavSpan>What is the path?</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('learnmore') > 0 ? (
                <SelectedLink
                  onClick={handleLearnMoreClick}
                  onKeyPress={handleLearnMoreClick}
                  tabIndex="0">
                  <NavDivSelected>
                    <SelectedSpan>Why does it matter?</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleLearnMoreClick}
                  onKeyPress={handleLearnMoreClick}
                  tabIndex="0">
                  <NavDivs>
                    <NavSpan>Why does it matter?</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('startplan') > 0 ? (
                <SelectedLink
                  onClick={handleStartPlanClick}
                  onKeyPress={handleStartPlanClick}
                  tabIndex="0">
                  <NavDivSelected>
                    <SelectedSpan>Plan my path</SelectedSpan>
                  </NavDivSelected>
                </SelectedLink>
              ) : (
                <a
                  onClick={handleStartPlanClick}
                  onKeyPress={handleStartPlanClick}
                  tabIndex="0">
                  <NavDivs>
                    <NavSpan>Plan my path</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
            <HorizontalListLi>
              {location.indexOf('findaprogram') > 0 ? (
                <a
                  onClick={handleFindAProgramClick}
                  onKeyPress={handleFindAProgramClick}
                  tabIndex="0">
                  <NavDivSelected>
                    <SelectedSpan>Find a program</SelectedSpan>
                  </NavDivSelected>
                </a>
              ) : (
                <a
                  onClick={handleFindAProgramClick}
                  onKeyPress={handleFindAProgramClick}
                  tabIndex="0">
                  <NavDivs>
                    <NavSpan>Find a program</NavSpan>
                  </NavDivs>
                </a>
              )}
            </HorizontalListLi>
          </HorizontalListUL>
        </MenuOuter>
      );
    }
    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <DesktopMenuLogic location={this.props.location}></DesktopMenuLogic>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <TabletMenuLogic location={this.props.location}></TabletMenuLogic>
        </Responsive>
        {/* mobile responsive code below */}
        <Responsive {...Responsive.onlyMobile}>
          <ExpandMenuLogic
            isExpanded={this.state.isExpanded}
            location={this.props.location}></ExpandMenuLogic>
        </Responsive>
      </Container>
    );
  }
}
export default NavMenu;
