import React, { Fragment } from 'react';
import { withStore } from '../../store';
import AddendumModal from './AddendumModal';

const AddendumQuestions = (props) => {
  return (
    <Fragment>
      <AddendumModal open={props.open} close={props.close} />
    </Fragment>
  );
};

export default withStore(AddendumQuestions);
