import React, { Fragment } from 'react';
import StartP2P from './StartP2P';
import Questions from './Questions';
import HowItWorks from './HowItWorks';
import queryString from 'query-string';

export default (props) => {
  var query = queryString.parse(props.location.search);
  return (
    <Fragment>
      <StartP2P open={query && query.modal == 'true' ? true : null} />
      <Questions />
      <HowItWorks />
    </Fragment>
  );
};
