import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  List,
  Responsive,
  Button,
  Icon,
  Divider,
  Image,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { Padding, H4, Link } from './Styles';
// import MobileMenu from '../../../assets/mobilemenu.svg';
// import Close from '../../../assets/close.svg';

const Container = styled.div`
  width: 100%;
  border: 1px #f5f5f5 solid;
  display: grid;
  align-items: center;
  background-color: #ffffff;
  z-index: 3;
  position: relative;
`;
const InviButton = styled(Button)`
  background: white !important;
  padding: 0px !important;
`;
const TealLink = styled.a`
  color: #007c91 !important;
  &:hover {
    text-decoration: none !important;
  }
`;
const RightAlignedCol = styled(Grid.Column)`
  text-align: right;
`;
const HamburgerMenu = styled(Button)`
  color: black !important;
  background: white !important;
  padding-right: 0px !important;
`;
const Logo = styled(Button)`
  color: black !important;
  background: white !important;
  padding-left: 0px !important;
`;

export const HorizontalListUL = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 690px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
`;
export const HorizontalListLi = styled.li`
  display: inline;
`;
export const MenuOuter = styled.div`
  background: #f5f5f5;
`;
export const NavDivs = styled.div`
  display: inline-block;
  width: 25%;

  height: 43px;

  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #262525;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;
export const NavSpan = styled.span`
  display: inline-block;
  padding-top: 8px;
  color: #262525;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  font-weight: 100;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  display: inline;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`;
export default (props) => {
  const history = useHistory();
  let textInput = null;
  useEffect(() => {
    textInput.focus();
  });
  const [open, setOpen] = useState(false);

  const handleHamburgerClick = () => {
    setOpen(!open);
  };
  const handleAboutClick = () => {
    history.push('/about');
  };
  const handleFindAProgramClick = () => {
    history.push('/findaprogram');
  };
  return (
    <div>
      <Container role="navigation">
        <Padding>
          <Grid>
            <Grid.Row>
              <Grid.Column floated="left" width={8}>
                <List horizontal>
                  <List.Item>
                    <div
                      ref={(button) => {
                        textInput = button;
                      }}>
                      <Responsive {...Responsive.onlyMobile}>
                        <Logo>
                          <Image
                            src="assets/P2P_Small.svg"
                            alt="P2P Logo"
                            href="./"
                            style={{
                              height: '24px',
                              width: '32px',
                              marginLeft: '15px',
                            }}></Image>
                        </Logo>
                      </Responsive>
                      <Responsive {...Responsive.onlyTablet}>
                        <Image
                          src="assets/P2P_Small.svg"
                          alt="P2P Logo"
                          href="./"
                          style={{
                            height: '21.09px',
                            width: '30px',
                            paddingLeft: '5px',
                          }}></Image>
                      </Responsive>
                      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        <Image
                          src="assets/P2P_Full.svg"
                          alt="P2P Logo"
                          href="./"
                          style={{ height: '35.06px', width: '150px' }}></Image>
                      </Responsive>
                    </div>
                  </List.Item>
                  <List.Item style={{ paddingLeft: '50px' }}>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                      <InviButton onClick={handleAboutClick}>
                        <H4 style={{ color: 'black' }}>About</H4>
                      </InviButton>
                    </Responsive>
                  </List.Item>
                </List>
              </Grid.Column>
              <Responsive
                as={RightAlignedCol}
                width={8}
                minWidth={Responsive.onlyTablet.minWidth}>
                <List divided horizontal>
                  <List.Item>
                    <InviButton
                      tabIndex="0"
                      onClick={handleFindAProgramClick}
                      role="link">
                      <H4 style={{ color: '#007c91' }}>Find a Program</H4>
                    </InviButton>
                  </List.Item>
                  <List.Item>
                    <H4>
                      <TealLink
                        href="https://www.cdc.gov/diabetes/risktest/index.html"
                        style={{ textDecoration: 'underline' }}>
                        Take the Risk Test
                      </TealLink>
                    </H4>
                  </List.Item>
                </List>
              </Responsive>
              <Responsive
                {...Responsive.onlyMobile}
                as={RightAlignedCol}
                width={8}>
                <HamburgerMenu
                  aria-label="mobile menu"
                  onClick={handleHamburgerClick}>
                  {!open ? (
                    <Image
                      alt="collapse menu"
                      size="small"
                      src="assets/mobilemenu.svg"
                      style={{
                        height: '30px',
                        width: '30px',
                        marginRight: '15px',
                      }}
                      onClick={handleHamburgerClick}></Image>
                  ) : (
                    <Image
                      alt="collapse menu"
                      size="small"
                      src="assets/Close_Background.svg"
                      style={{
                        height: '30px',
                        width: '30px',
                        marginRight: '15px',
                      }}
                      onClick={handleHamburgerClick}></Image>
                  )}
                </HamburgerMenu>
              </Responsive>
            </Grid.Row>
          </Grid>
          {open ? (
            <Responsive {...Responsive.onlyMobile}>
              <HorizontalListUL>
                <li>
                  <a href="./" tabIndex="0" style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>
                        <H4>Home</H4>
                      </NavSpan>
                    </NavDivs>
                  </a>
                </li>
                <li>
                  <a href="/about" tabIndex="0" style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>
                        <H4>About</H4>
                      </NavSpan>
                    </NavDivs>
                  </a>
                </li>
                <li>
                  <a
                    href="/findaprogram"
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>
                        <H4 style={{ color: '#007c91' }}>Find a Program</H4>
                      </NavSpan>
                    </NavDivs>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/diabetes/risktest/index.html"
                    tabIndex="0"
                    style={{ width: '100%' }}>
                    <NavDivs style={{ width: '100%' }}>
                      <NavSpan>
                        <H4 style={{ color: '#007c91' }}>Take the Risk Test</H4>
                      </NavSpan>
                    </NavDivs>
                  </a>
                </li>
              </HorizontalListUL>
            </Responsive>
          ) : (
            ''
          )}
        </Padding>
      </Container>
      {open ? <Overlay /> : ''}
    </div>
  );
};
