import React, { Fragment } from 'react';
import styled from 'styled-components';

export const Padding = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (min-width: 768px) {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 31px;
    padding-right: 31px;
  }
`;

export const Link = styled.a`
  color: black;
`;
