import React, { useState, useRef, Component } from 'react';
import styled from 'styled-components';

import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';

import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  AP7DivBorder,
  AP7description,
  AP7bullets,
  AP7bulletnote,
  AP7NameLine,
  EditPlanText,
  NoPrintDiv,
  AP7ReadyText,
} from './Styles';
const IconImage = styled(Image)`
  height: 25px !important;
  width: 25px !important;
  margin: 28px auto 7px auto !important;
  @media only screen and (min-width: 768px) {
    height: 30px !important;
    width: 30px !important;
  }
  @media only screen and (min-width: 992px) {
    height: 35px !important;
    width: 35px !important;
  }
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  margin: 0 !important;
  line-height: 0 !important;
  margin-top: 44px !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    display: inline-block;
    width: 270px;
    margin-left: 150px !important;
  }
  @media only screen and (min-width: 992px) {
    display: inline-block;
    width: 270px;
    margin-left: 150px !important;
  }
`;

class ActionPlan7 extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {
    const enterKey = 'Enter';

    const handleButtonClick = () => {
      this.props.store.set('currentActionPlan', '8');
    };
    const handleEditPlanClick = () => {
      this.props.store.set('currentActionPlan', '1');
    };
    const handleKeyPress = (event) => {
      if (event.key === enterKey) {
        this.props.store.set('currentActionPlan', '1');
      }
    };
    const handlePrintKeyPress = (event) => {
      if (event.key === enterKey) {
        window.print();
      }
    };
    const handlePrintClick = () => {
      window.print();
    };
    const handleBackClick = () => {
      this.props.store.set('currentActionPlan', '6'); 
    };

    const PlanSummaryCode = (parent) => {
      var name = parent.parent.props.store.UserName;
      name = name[0].toUpperCase() + name.slice(1);
      return (
        <div>
          {' '}
          <IconImage
            size="medium"
            src="assets/P2P_Small.svg"
            alt="Welcome page icon"
          ></IconImage>
          <AP7NameLine>{name}'s Action Plan</AP7NameLine>
          <AP7DivBorder>
            <AP7description>
              I am taking this step towards a healthier life because I want to:
            </AP7description>
            {parent.parent.props.store.AP2Items.map((i) => {
              if (i.isSelected) {
                return <AP7bullets key={i.text}>• {i.text}</AP7bullets>;
              }
            })}
            {parent.parent.props.store.AP2OtherText.length > 0 ? (
              <AP7bullets key={parent.parent.props.store.AP2OtherText}>
                • {parent.parent.props.store.AP2OtherText}
              </AP7bullets>
            ) : (
              <div></div>
            )}
          </AP7DivBorder>
          <AP7DivBorder>
            <AP7description>
              Before enrolling in the program and attend classes for a year, I
              need to plan for:
            </AP7description>
            {parent.parent.props.store.AP4Items.map((i) => {
              if (i.isSelected) {
                return <AP7bullets key={i.text}>• {i.text}</AP7bullets>;
              }
            })}
            {parent.parent.props.store.AP4OtherText.length > 0 ? (
              <AP7bullets key={parent.parent.props.store.AP4OtherText}>
                • {parent.parent.props.store.AP4OtherText}
              </AP7bullets>
            ) : (
              <div></div>
            )}
          </AP7DivBorder>
          <AP7DivBorder>
            <AP7description>
              My prefered Program Provider will offer classes that take place:
            </AP7description>
            {parent.parent.props.store.AP5Items.map((i) => {
              if (i.isSelected) {
                return <AP7bullets key={i.text}>• {i.text}</AP7bullets>;
              }
            })}
            {parent.parent.props.store.AP5Times.map((i) => {
              if (i.isSelected) {
                return <AP7bullets key={i.text}>• {i.text}</AP7bullets>;
              }
            })}
            <br></br>
            <AP7bulletnote>
              Don't forget to use our Find a Program Finder to find a program
              provider that meets your preferences and contact them!
            </AP7bulletnote>
          </AP7DivBorder>
          <AP7DivBorder>
            <AP7description>
              Once I find a Program Provider, I will contact them by:
            </AP7description>
            <AP7bullets key={parent.parent.props.store.date}>
              • {parent.parent.props.store.date}
            </AP7bullets>
          </AP7DivBorder>
          <div style={{ textAlign: 'center' }}>
            <AP7ReadyText>
              I, {name}, am ready to begin my Path to Prevention{' '}
            </AP7ReadyText>
          </div>
        </div>
      );
    };
    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>7/7</PageNumber>
                    <PageTitle>Completed Plan</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                You have completed your Path 2 Prevention Action Plan! Review
                your plan below, print a copy, then move forward.
              </BlueTextContainer>
              <PlanSummaryCode parent={this} />
              <Image
                src="assets/Edit icon.svg"
                style={{
                  width: '17px',
                  height: '17px',
                  display: 'inline-block',
                }}
              ></Image>
              <EditPlanText
                onClick={handleEditPlanClick}
                tabIndex="0"
                aria-label="edit plan reset button"
                onKeyDown={handleKeyPress}
              >
                Edit My Plan
              </EditPlanText>
              <Image
                src="assets/print icon.svg"
                style={{
                  width: '17px',
                  height: '17px',
                  display: 'inline-block',
                  marginLeft: '32px',
                }}
              ></Image>
              <EditPlanText
                onClick={handlePrintClick}
                tabIndex="0"
                aria-label="edit plan reset button"
                onKeyDown={handlePrintKeyPress}
              >
                Print My Plan
              </EditPlanText>
              <NoPrintDiv>
                <CustomButton onClick={handleButtonClick}>
                  Move Forward
                </CustomButton>
              </NoPrintDiv>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>7/7</PageNumber>
                    <PageTitle>Completed Plan</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                You have completed your Path 2 Prevention Action Plan! Review
                your plan below, print a copy, then move forward.
              </BlueTextContainer>
              <PlanSummaryCode parent={this} />
              <NoPrintDiv style={{ display: 'inline-block' }}>
                {' '}
                <div style={{ display: 'block' }}>
                  <Image
                    src="assets/Edit icon.svg"
                    style={{
                      width: '17px',
                      height: '17px',
                      display: 'inline-block',
                    }}
                  ></Image>
                  <EditPlanText
                    onClick={handleEditPlanClick}
                    tabIndex="0"
                    aria-label="edit plan reset button"
                    onKeyDown={handleKeyPress}
                  >
                    Edit My Plan
                  </EditPlanText>
                </div>
                <div style={{ display: 'block' }}>
                  <Image
                    src="assets/print icon.svg"
                    style={{
                      width: '17px',
                      height: '17px',
                      display: 'inline-block',
                    }}
                  ></Image>
                  <EditPlanText
                    onClick={handlePrintClick}
                    tabIndex="0"
                    aria-label="edit plan reset button"
                    onKeyDown={handlePrintKeyPress}
                  >
                    Print My Plan
                  </EditPlanText>
                </div>
              </NoPrintDiv>
              <NoPrintDiv>
                <CustomButton onClick={handleButtonClick}>
                  Move Forward
                </CustomButton>
              </NoPrintDiv>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '40%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>7/7</PageNumber>
                    <PageTitle>Completed Plan</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                You have completed your Path 2 Prevention Action Plan! Review
                your plan below, print a copy, then move forward.
              </BlueTextContainer>
              <PlanSummaryCode parent={this} />
              <Image
                src="assets/Edit icon.svg"
                style={{
                  width: '17px',
                  height: '17px',
                  display: 'inline-block',
                }}
              ></Image>
              <EditPlanText
                onClick={handleEditPlanClick}
                tabIndex="0"
                aria-label="edit plan reset button"
                onKeyDown={handleKeyPress}
              >
                Edit My Plan
              </EditPlanText>
              <Image
                src="assets/print icon.svg"
                style={{
                  width: '17px',
                  height: '17px',
                  display: 'inline-block',
                  marginLeft: '52px',
                }}
              ></Image>
              <EditPlanText
                onClick={handlePrintClick}
                tabIndex="0"
                aria-label="edit plan reset button"
                onKeyDown={handlePrintKeyPress}
              >
                Print My Plan
              </EditPlanText>
              <NoPrintDiv>
                <CustomButton onClick={handleButtonClick}>
                  Move Forward
                </CustomButton>
              </NoPrintDiv>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
      </Container>
    );
  }
}

export default withStore(ActionPlan7);
