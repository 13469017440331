import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  APContent,
  EnterNameText,
  InputName,
  ImageDiv,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
import { render } from 'react-dom';

const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  line-height: 0 !important;
  margin: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    width: 324px;
  }
  @media only screen and (min-width: 992px) {
    width: 324px;
  }
`;

class ActionPlan1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      error: false,
      isExpanded: false,
      selectedItems: [],

      somethingSelected: false,
      payloadBox: '',
    };

    if (this.props.store.UserName != undefined) {
      this.state.UserName = this.props.store.UserName;
    }
    window.scrollTo(0, 0);
  }
  render() {
    const handleButtonClick = () => {
      if (this.state.UserName.length > 0) {
        this.props.store.set('UserName', this.state.UserName);
        this.props.store.set('currentActionPlan', '2');
      } else {
        this.props.store.set('errorAP1', true);
      }
    };
    const handleBackClick = () => {
      this.props.store.set('currentActionPlan', '');

    };
    const handleInputText = (val) => {
      if (val.target.value.length > 0) {
        this.props.store.set('errorAP1', false);
      }
      this.state.UserName = val.target.value;
    };

    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{ color: '#F5F5F5', fontSize: '30px' }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                 
                              alt="back button to main page"
                            
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                           
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>1/7</PageNumber>
                    <PageTitle>General information</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Welcome to your personalized Action Plan. After you answer a few
                more questions, your plan will be finished. Let’s start with the
                basics.
              </BlueTextContainer>
              <APContent>
                <EnterNameText>What is your first name? </EnterNameText>
                <form>
                  <fieldset>
                    <InputName
                      aria-label="input first name"
                      type="text"
                      maxLength="40"
                      value={this.state.UserName}
                      onChange={handleInputText}
                    ></InputName>
                  </fieldset>
                </form>
                <ImageDiv>
                  <Image
                    alt="action plan image 1"
                    src="assets/AP_Step_1_Illustration.svg"
                    style={{
                      height: '102px',
                      width: '132px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  ></Image>
                </ImageDiv>
                <CustomButton onClick={handleButtonClick}>
                  Identify Motivators
                </CustomButton>
                {!this.props.store.errorAP1 ? (
                  <H5 style={{ height: '19px', width: '100%' }}> </H5>
                ) : (
                  ''
                )}
                {this.props.store.errorAP1 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Enter your name above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </APContent>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {' '}
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>1/7</PageNumber>
                    <PageTitle>General information</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Welcome to your personalized Action Plan. After you answer a few
                more questions, your plan will be finished. Let’s start with the
                basics.
              </BlueTextContainer>
              <APContent>
                <EnterNameText>What is your first name? </EnterNameText>
                <form>
                  <fieldset>
                    <InputName
                      aria-label="input first name"
                      type="text"
                      maxLength="40"
                      value={this.state.UserName}
                      onChange={handleInputText}
                    ></InputName>
                  </fieldset>
                </form>
                <ImageDiv>
                  <Image
                    alt="action plan image 1"
                    src="assets/AP_Step_1_Illustration.svg"
                    style={{
                      height: '102px',
                      width: '132px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  ></Image>
                </ImageDiv>
                <CustomButton onClick={handleButtonClick}>
                  Identify Motivators
                </CustomButton>
                {!this.props.store.errorAP1 ? (
                  <H5 style={{ height: '19px', width: '100%' }}> </H5>
                ) : (
                  ''
                )}
                {this.props.store.errorAP1 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Enter your name above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </APContent>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '40%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                 
                              alt="back button to main page"
                            
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>1/7</PageNumber>
                    <PageTitle>General information</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Welcome to your personalized Action Plan. After you answer a few
                more questions, your plan will be finished. Let’s start with the
                basics.
              </BlueTextContainer>
              <APContent>
                <EnterNameText>What is your first name? </EnterNameText>
                <form>
                  <fieldset>
                    <InputName
                      aria-label="input first name"
                      type="text"
                      maxLength="40"
                      value={this.state.UserName}
                      onChange={handleInputText}
                    ></InputName>
                  </fieldset>
                </form>
                <ImageDiv>
                  <Image
                    alt="action plan image 1"
                    src="assets/AP_Step_1_Illustration.svg"
                    style={{
                      height: '102px',
                      width: '132px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  ></Image>
                </ImageDiv>
                <CustomButton onClick={handleButtonClick}>
                  Identify Motivators
                </CustomButton>
                {!this.props.store.errorAP1 ? (
                  <H5 style={{ height: '19px', width: '100%' }}> </H5>
                ) : (
                  ''
                )}
                {this.props.store.errorAP1 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Enter your name above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </APContent>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
      </Container>
    );
  }
}

export default withStore(ActionPlan1);
