import React from 'react';
import styled from 'styled-components';
// import CDCLogo from '../../../assets/cdclogo.svg';
// import CDCLogoSmall from '../../../assets/cdclogosmall.svg';
import { Image, Responsive } from 'semantic-ui-react';
import { Padding } from './Styles';

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  border: 1px #f5f5f5 solid;
  z-index: 3;
  position: relative;
`;

export default (props) => {
  return (
    <Container role="banner">
      <Padding>
        <h1 hidden>path 2 prevention</h1>
        <Responsive
          as={Image}
          {...Responsive.onlyMobile}
          src="assets/cdclogosmall.svg"
          alt="CDC Logo"
        />
        <Responsive
          as={Image}
          minWidth={Responsive.onlyTablet.minWidth}
          src="assets/cdclogo.svg"
          alt="CDC Logo"
        />
      </Padding>
    </Container>
  );
};
