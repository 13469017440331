import React, { Fragment } from "react";
import styled from "styled-components";

export const Padding = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const H1 = styled.h1`
  font-size: calc(28px + (56 - 28) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  margin: 0px;
  font-family: Poppins !important;
`;
export const H1About = styled.h1`
  font-size: 48px;
  font-weight: 600;
  margin: 0px;
  font-family: Poppins !important;
  color: #ffffff;
  letter-spacing: 0.69px;
  line-height: 62px;
`;
export const H1AboutSmall = styled.h1`
  color: #333333;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.46px;
  line-height: 50px;
`;
export const PAboutDescriptions = styled.p`
  color: #000000;
  font-family: "Avenir Next";
  font-size: 22px;
  letter-spacing: 0.32px;
  line-height: 30px;
  text-align: center;
  marginLeft: 'auto',
  marginRight: 'auto',

`;
export const H2 = styled.h2`
  font-size: calc(22px + (32 - 22) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  font-family: Poppins !important;
`;
export const H3 = styled.h3`
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  font-family: Poppins !important;
`;
export const H3Light = styled.h3`
  font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 100;
  font-family: Avenir Next !important;
`;
export const P = styled.p`
  color: #ffffff;
  font-family: "Avenir Next" !important;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 30px;
  text-align: center;
`;
export const H4 = styled.h4`
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  font-family: Poppins !important;
`;
export const Link = styled.a`
  color: black;
`;
