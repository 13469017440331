import React, { Fragment } from 'react';
import { Responsive, Form } from 'semantic-ui-react';
import { H4, H5, GrayButton } from './Styles';
import { withStore } from '../../store';

const styles = {
  radio: {
    fontSize: '18px',
    letterSpacing: '0.26px',
    fontWeight: '500',
    fontFamily: 'Poppins !important',
  },
};

const questions = {
  1: {
    text1:
      'Are you actively taking steps to eat healthy and/or be more physically active?',
    answers: ['Yes', 'No'],
    text2:
      ' Examples of healthy eating include cutting back on the amount of sugar and processed food you eat and/or eating more vegetables and lean meats. Examples of being more physically active include adding more walking or exercise into your daily routine.',
  },
  2: {
    text1:
      'How likely are you to work toward making changes like eating healthier or being more physically active in the next 6 months? ',
    answers: ['Likely', 'Somewhat Likely', 'Not Likely'],
    text2:
      'Increased physical activity and healthier eating are two key habits that can reduce your risk of developing type 2 diabetes.',
  },
  3: {
    text1:
      'Would you consider enrolling in the National Diabetes Prevention Program in the next 30 days? ',
    answers: ['Yes', 'No'],
    text2:
      'The National Diabetes Prevention Program (National DPP) gives you access to a trained coach that can help you add healthy behaviors to your lifestyle. Individuals enrolled in the National DPP can cut their chances of developing type 2 diabetes in half. ',
  },
};

const Question1 = (props) => {
  const handleChange = (val) => {
    props.store.set('error', false);
    if (val == props.store.answer1) {
      props.store.set('answer1', '');
      props.store.set('currentQuestion', '1');
    } else {
      props.store.set('answer1', val);
      val == 'No'
        ? props.store.set('currentQuestion', '2')
        : props.store.set('currentQuestion', '1');
    }
  };

  return (
    <Fragment>
      <H5 style={{ color: '#262525' }}>Question 1</H5>
      <br />
      <H4>{questions[1].text1}</H4>
      <br />

      <Responsive {...Responsive.onlyMobile}>
        {questions[1].answers.map((val, index) => {
          return (
            <GrayButton
              key={index}
              size="massive"
              fluid
              onClick={(event) => handleChange(val)}
              style={
                props.store.answer1 == val
                  ? { border: '2.5px solid #39536c' }
                  : { border: '1.5px solid #bed0e1' }
              }>
              <Form.Radio
                label={val}
                value={val}
                style={styles.radio}
                checked={props.store.answer1 == val}
              />
            </GrayButton>
          );
        })}
      </Responsive>
      <Responsive
        minWidth={Responsive.onlyTablet.minWidth}
        style={{
          textAlign: 'center',
        }}>
        {questions[1].answers.map((val, index) => {
          return (
            <GrayButton
              key={index}
              size="massive"
              compact
              onClick={(event) => handleChange(val)}
              style={
                props.store.answer1 == val
                  ? { border: '2.5px solid #39536c' }
                  : { border: '1.5px solid #bed0e1' }
              }>
              <Form.Radio
                label={val}
                value={val}
                style={styles.radio}
                checked={props.store.answer1 == val}
                tabIndex={-1}
                title={val}
              />
            </GrayButton>
          );
        })}
      </Responsive>
      <br />
      <H5 style={{ color: '#333333' }}>{questions[1].text2}</H5>
    </Fragment>
  );
};

export default withStore(Question1);
