import React, { Fragment, useEffect } from 'react';
import { H1 } from './Styles';
import WhatIsP2p from './WhatIsP2P';
import BackButton from './BackButton';
import WhatIsPreDiabetes from './WhatIsPrediabetes';
import WhatIsNDPP from './WhatIsNDPP';
import WhereToStart from './WhereToStart';

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      {/* <H1>About Us Page</H1> */}
      <BackButton></BackButton>
      <WhatIsP2p></WhatIsP2p>
      <WhatIsPreDiabetes></WhatIsPreDiabetes>

      <WhatIsNDPP></WhatIsNDPP>
      <WhereToStart></WhereToStart>
    </Fragment>
  );
};
