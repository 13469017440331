import React, { Fragment } from 'react';
import styled from 'styled-components';

export const MenuOuter = styled.div`
  height: 84px;
  background: #f5f5f5;
`;

export const Table = styled.table`
  display: table; /* Allow the centering to work */
  margin: 0 auto;
  max-width: 1140px;
`;

export const HorizontalListUL = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 690px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
`;

export const HorizontalListLi = styled.li`
  display: inline;
`;

export const NavDivs = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: inline-block;
  width: 25%;

  height: 43px;

  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #262525;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;
export const NavDivSelectedMobile = styled.div`
  display: inline-block;
  width: 25%;

  height: 43px;

  border: 1px solid #39536c;
  background-color: #39536c;
  color: #fafeff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  &:after {
    content: ' ';
    display: inline-block;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;

    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(0px);
    transform: translateX(11px);
    transition: transform 0.2s ease-out;
  }
`;

export const NavDivSelected = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: inline-block;
  width: 25%;

  height: 43px;

  border: 1px solid #39536c;
  background-color: #39536c;
  color: #fafeff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;
export const SelectedLink = styled.a`
  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;

    border: 8px solid transparent;
    border-left: 8px solid #39536c;
    transform: translateY(13.5px);
  }
`;
export const NavLabel = styled.label`
  color: #262525;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;
export const NavLabelSelected = styled.p`
  color: #fafeff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;
export const SelectedSpan = styled.span`
  display: inline-block;
  padding-top: 8px;
  color: #fafeff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;
export const NavSpan = styled.span`
  display: inline-block;
  padding-top: 8px;
  color: #262525;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  font-weight: 100;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  display: inline;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`;
export const GreenHyperlink = styled.a`
  text-decoration-thickness: 4px;
  text-decoration: underline;
  color: #007c91;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.26px;
  line-height: 25px;
  text-align: left;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
  }
  &:hover {
    text-decoration: none !important;
  }
`;

export const ContinueButton = styled.button`
  &:hover {
    cursor: pointer;
  }
  background-color: #007c91 !important;
  padding: 0;
  height: 48px;
  width: 345;
  color: #ffffff;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-align: center;
  border: 0;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    width: 272px;
  }
  @media only screen and (min-width: 992px) {
    width: 253px;
  }
`;
export const Text = styled.p`
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  letter-spacing: 0.23px;
  line-height: 22px;
  color: #333333;
  padding-bottom: 20px;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    text-align: left;
  }
`;
export const H1 = styled.h1`
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;

  @media only screen and (min-width: 768px) {
    letter-spacing: 0.32px;

    font-size: 22px;
    text-align: left;
  }
  @media only screen and (min-width: 992px) {
  }
`;
export const H2 = styled.h2`
  padding-top: 19px;
  margin-top: 0;
  font-family: Nunito;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 20px;
  color: #333333;
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
    padding-top: 13px;
  }
  @media only screen and (min-width: 992px) {
  }
`;
