import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
  Grid,
} from 'semantic-ui-react';
import {
  Padding,
  H1,
  H3Light,
  H3,
  P,
  H1AboutSmall,
  PAboutDescriptions,
  LargeButtonText,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  line-height: 0 !important;
  background-color: #007c91 !important;

  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

export default (props) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const handleLetsGoClick = () => {
    setOpen(true);
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <div
          style={{
            paddingTop: '123px',
            maxWidth: '540px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall style={{ textAlign: 'center', marginBottom: '20px' }}>
            Where do I start?
          </H1AboutSmall>
          <PAboutDescriptions
            style={{ textAlign: 'center', marginBottom: '32px' }}>
            Get started with Path 2 Prevention today! Check out the different
            activities and information here to get started on your path.{' '}
          </PAboutDescriptions>
        </div>
        <Responsive
          minWidth={Responsive.onlyComputer.minWidth}
          onClick={handleLetsGoClick}
          as={CustomButton}
          size="massive"
          aria-label="start path to prevention"
          inverted
          style={{ marginBottom: '145px', width: '351px' }}>
          <LargeButtonText>Let's Go!</LargeButtonText>
        </Responsive>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <div
          style={{
            paddingTop: '61px',
            maxWidth: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall
            style={{
              fontSize: '26',
              textAlign: 'center',
              marginBottom: '21px',
            }}>
            Where do I start?
          </H1AboutSmall>
          <PAboutDescriptions
            style={{
              fontSize: '18px',
              textAlign: 'center',
              marginBottom: '50px',
            }}>
            Get started with Path 2 Prevention today! Check out the different
            activities and information here to get started on your path.{' '}
          </PAboutDescriptions>
        </div>
        <Responsive
          {...Responsive.onlyTablet}
          onClick={handleLetsGoClick}
          as={CustomButton}
          size="massive"
          inverted
          style={{ marginBottom: '74px', width: '330px' }}>
          <LargeButtonText>Let's Go!</LargeButtonText>
        </Responsive>
      </Responsive>
      {/* mobile responsive code below */}
      <Responsive {...Responsive.onlyMobile}>
        <div
          style={{
            paddingTop: '94px',
            maxWidth: '345px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <H1AboutSmall
            style={{
              textAlign: 'center',
              marginBottom: '34px',
              fontSize: '24px',
              letterSpacing: ' 0.35px',
              lineHeight: '32px',
            }}>
            Where do I start?
          </H1AboutSmall>
          <PAboutDescriptions
            style={{
              textAlign: 'center',
              fontSize: '18px',
              letterSpacing: '0.26px',
              lineHeight: '24px',
              marginBottom: '59px',
            }}>
            Get started with Path 2 Prevention today! Check out the different
            activities and information here to get started on your path.{' '}
          </PAboutDescriptions>
        </div>
        <Responsive
          {...Responsive.onlyMobile}
          onClick={handleLetsGoClick}
          as={CustomButton}
          size="massive"
          inverted
          fluid
          style={{ margin: '0 15px 96px', maxWidth: '345px' }}>
          <LargeButtonText>Let's Go!</LargeButtonText>
        </Responsive>
      </Responsive>
      <AddendumQuestions open={open} close={close} />
    </Container>
  );
};
