import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Responsive, Grid, Image } from 'semantic-ui-react';
import { BodyPadding, H1, H3, H5, LargeButtonText } from './Styles';
import AddendumQuestions from '../AddendumQuestions';
import { useHistory } from 'react-router-dom';
// import HomePageIllustration from '../../../assets/HomePageIllustration.svg';

const Container = styled.div`
  width: 100%;
  text-align: center;
  @media only screen and (min-width: 992px) {
    text-align: left;
  }
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 390px;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

export default (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(props.open ? props.open : false);
  const close = () => {
    setOpen(false);
    // document.getElementsByTagName('body')[0].style.overflow = '';
    document.getElementsByTagName('body')[0].classList.remove('dimmable');
    document.getElementsByTagName('body')[0].classList.remove('dimmed');
    document.getElementsByTagName('body')[0].classList.remove('scrolling');
  };

  const handleLetsGoClick = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (width < 768) {
      history.push('/addendumquestions');
    } else {
      setOpen(true);
      document.getElementsByTagName('body')[0].classList.add('dimmable');
      document.getElementsByTagName('body')[0].classList.add('dimmed');
      document.getElementsByTagName('body')[0].classList.add('scrolling');
    }
    // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };
  return (
    <Container role="main" aria-label="Get Started Container">
      <BodyPadding>
        <Grid columns={2} doubling stackable>
          <Grid.Row>
            <Grid.Column>
              <H1>Start Your Path 2 Prevention</H1>
              <H3>
                Get started on your path to preventing type 2 diabetes here.
              </H3>
              <br />
              <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                <CustomButton
                  onClick={handleLetsGoClick}
                  size="massive"
                  inverted>
                  <LargeButtonText>Let's Go!</LargeButtonText>
                </CustomButton>
                <br />
                <br />
                <H5>
                  Created by the Centers for Disease Control and Prevention
                </H5>
              </Responsive>
            </Grid.Column>
            <Grid.Column>
              <Image
                src="assets/HomePageIllustration.svg"
                alt=""
                centered></Image>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Responsive {...Responsive.onlyTablet}>
                <CustomButton
                  onClick={handleLetsGoClick}
                  size="massive"
                  inverted>
                  <LargeButtonText>Let's Go!</LargeButtonText>
                </CustomButton>
                <br />
                <br />
                <H5>
                  Created by the Centers for Disease Control and Prevention
                </H5>
              </Responsive>
              <Responsive {...Responsive.onlyMobile}>
                <CustomButton
                  onClick={handleLetsGoClick}
                  size="massive"
                  inverted
                  fluid>
                  <LargeButtonText>Let's Go!</LargeButtonText>
                </CustomButton>
                <br />
                <H5>
                  Created by the Centers for Disease Control and Prevention
                </H5>
              </Responsive>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </BodyPadding>
      <AddendumQuestions open={open} close={close} />
    </Container>
  );
};
