import React, { useState } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import {
  Responsive,
  Button,
  Image,
  Icon,
  Grid,
  Label,
} from 'semantic-ui-react';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  EnterNameText,
  InputName,
  APContent,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
import DateTimeForm from './DateSelection';

export const Suggestion = styled(Label)`
  border: 0.2px solid #bdbdbd !important;
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 317px;
  font-family: Nunito;
  font-size: 14px !important;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    padding-top: 24px !important;
    width: 316px;
  }
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  margin: 0 !important;
  line-height: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

const ActionPlan6 = (props) => {
  window.scrollTo(0, 0);
  const error = props.store.errorAP6;
  const [UserName, setUser] = useState('');

  const handleButtonClick = () => {
    if (props.store.date != undefined) {
      props.store.set('errorAP6', false);
      props.store.set('currentActionPlan', '7');
    } else {
      props.store.set('errorAP6', true);
    }
  };
  const handleBackClick = () => {
    props.store.set('currentActionPlan', '5'); 
  };
  const handleInputText = (val) => {
    if (val.target.value.length > 0) {
      props.store.set('errorAP6', false);
    }
    setUser(val.target.value);
  };

  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      aria-hidden="false"
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '30px',
                      }}
                    />
                    <Icon
                      aria-hidden="false"
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '48%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber>6/7</PageNumber>
                  <PageTitle>Select a Date</PageTitle>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <BlueTextContainer>
              Last step! So far you have identified your motivation, your
              obstacles, and the type of class for you. Now, pick a date to
              contact a program provider.
            </BlueTextContainer>

            <APContent>
              <EnterNameText>
                I will contact a program provider by:
              </EnterNameText>

              <DateTimeForm></DateTimeForm>
              <Suggestion pointing>
                Pick a date that works for your schedule, then add it to your
                calendar, write yourself a reminder, or even tell your family
                and friends about your plans!{' '}
              </Suggestion>
            </APContent>
            <br></br>
            <br></br>
            <CustomButton onClick={handleButtonClick}>Review Plan</CustomButton>
            {error ? (
              <H5 style={{ color: '#BF2600' }}>
                Choose a date before moving forward!{' '}
              </H5>
            ) : (
              ''
            )}
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      aria-hidden="false"
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '30px',
                      }}
                    />

                    <Icon
                      aria-hidden="false"
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '48%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber>6/7</PageNumber>
                  <PageTitle>Select a Date</PageTitle>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <BlueTextContainer>
              Last step! So far you have identified your motivation, your
              obstacles, and the type of class for you. Now, pick a date to
              contact a program provider.
            </BlueTextContainer>

            <APContent>
              <EnterNameText>
                I will contact a program provider by:
              </EnterNameText>

              <DateTimeForm></DateTimeForm>
              <Suggestion pointing>
                Pick a date that works for your schedule, then add it to your
                calendar, write yourself a reminder, or even tell your family
                and friends about your plans!{' '}
              </Suggestion>
            </APContent>
            <br></br>
            <br></br>
            <CustomButton onClick={handleButtonClick}>Review Plan</CustomButton>
            {error ? (
              <H5 style={{ color: '#BF2600' }}>
                Choose a date before moving forward!{' '}
              </H5>
            ) : (
              ''
            )}
          </WhiteContainer>
        </GrayContainer>
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '25px',
                      }}
                    />
                    <Icon
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '40%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber>6/7</PageNumber>
                  <PageTitle>Select a Date</PageTitle>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <BlueTextContainer>
              Last step! So far you have identified your motivation, your
              obstacles, and the type of class for you. Now, pick a date to
              contact a program provider.
            </BlueTextContainer>

            <APContent>
              <EnterNameText>
                I will contact a program provider by:
              </EnterNameText>

              <DateTimeForm></DateTimeForm>
              <Suggestion pointing>
                Pick a date that works for your schedule, then add it to your
                calendar, write yourself a reminder, or even tell your family
                and friends about your plans!{' '}
              </Suggestion>
            </APContent>
            <br></br>
            <br></br>
            <CustomButton onClick={handleButtonClick}>Review Plan</CustomButton>
            {error ? (
              <H5 style={{ color: '#BF2600' }}>
                Choose a date before moving forward!{' '}
              </H5>
            ) : (
              ''
            )}
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
    </Container>
  );
};

export default withStore(ActionPlan6);
