import React, { Fragment, useEffect } from 'react';
import { H1 } from '../Styles/Styles';
import NavMenu from '../BeginPath/NavMenu';
import FindAProgram from './FindAProgram';
export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <NavMenu location={props.location.pathname}></NavMenu>
      <FindAProgram />
    </Fragment>
  );
};
