import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H4, Text, LargeButtonText, H1 } from './Styles';
import { Button, Responsive, Image } from 'semantic-ui-react';
import { withStore } from '../../store';

const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 285px;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

const IconImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-bottom: 28px;
`;

const Welcome = (props) => {
  const handleStartGameClick = () => {
    props.store.set('current_game_state', 1);
    props.store.set('current_game_score', 0);
  };
  return (
    <Fragment>
      <IconImage
        size="mini"
        src="assets/game.svg"
        alt="game icon"
        centered
      ></IconImage>
      <H1>Welcome to Myth vs. Fact!</H1>
      <br />
      <br />
      <Text>
        Play the game below to test your knowledge of prediabetes, type 2
        diabetes, and the National DPP. You may learn something new along the
        way.
      </Text>
      <br />
      <br />
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <CustomButton inverted onClick={handleStartGameClick}>
          <LargeButtonText>Play the Game</LargeButtonText>
        </CustomButton>
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <CustomButton inverted fluid onClick={handleStartGameClick}>
          <LargeButtonText>Play the Game</LargeButtonText>
        </CustomButton>
      </Responsive>
    </Fragment>
  );
};

export default withStore(Welcome);
