import React, { Fragment } from 'react';
import styled from 'styled-components';

export const BodyPadding = styled.div`
  padding-top: 38px;
  padding-bottom: 96px;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (min-width: 768px) {
    padding-top: 78px;
    padding-bottom: 78px;
    padding-left: 39px;
    padding-right: 39px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 58px;
    padding-bottom: 116px;
    padding-left: 153px;
    padding-right: 153px;
  }
`;
export const H1 = styled.h1`
  font-weight: 600;
  font-family: Poppins !important;
  font-size: 32px;
  letter-spacing: 0.46px;
  line-height: 30px;
  @media only screen and (min-width: 768px) {
    font-size: 42px;
    letter-spacing: 0.61px;
    line-height: 40px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 56px;
    letter-spacing: 0.81px;
    line-height: 59px;
    max-width: 536px;
    margin-right: auto;
  }
`;
export const H2 = styled.h1`
  font-weight: 600;
  font-family: Poppins !important;
  font-size: 24px;
  letter-spacing: 0.35px;
  line-height: 36px;
  @media only screen and (min-width: 768px) {
    font-size: 26px;
    letter-spacing: 0.38px;
    line-height: 36px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 32px;
    letter-spacing: 0.46px;
    line-height: 62px;
  }
`;
export const H3 = styled.div`
  font-family: Nunito !important;
  color: #333333 !important;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 30px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 24px;
    letter-spacing: 0.35px;
    line-height: 33px;
  }
`;
export const Text = styled.div`
  font-family: Nunito !important;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 24px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 30px;
  }
`;
export const H4 = styled.div`
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 25px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 25px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 30px;
  }
`;

export const H5 = styled.div`
  color: #39536c;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 19px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
`;

export const LargeButtonText = styled.div`
  font-size: 22px;
  font-weight: 500;
  font-family: Poppins !important;
`;
export const SmallButtonText = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins !important;
`;
export const Link = styled.a`
  color: black;
`;
