import React, { useState, Component } from 'react';
import { Responsive, Form } from 'semantic-ui-react';
import { withStore } from '../../store';
import Moment from 'moment';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput,
} from 'semantic-ui-calendar-react';
class DateTimeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: '',
      time: '',
      dateTime: '',
      datesRange: '',
    };
    if (this.props.store.date != undefined) {
      this.state.date = this.props.store.date;
    }
  }

  handleChange = (event, { name, value }) => {
    this.props.store.set('date', value);
    //if (this.state.hasOwnProperty(name)) {
    this.setState({ [name]: value });
    // }
  };

  render() {
    var Today = new Date();
    return (
      <Form>
        <Form.Field>
          <DateInput
            name="date"
            hideMobileKeyboard
            placeholder="MM/DD/YYYY"
            value={this.state.date}
            iconPosition="right"
            onChange={this.handleChange}
            dateFormat="MM/DD/YYYY"
            closable={true}
            minDate={Today}
          />
        </Form.Field>
      </Form>
    );
  }
}
export default withStore(DateTimeForm);
