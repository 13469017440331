import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
} from 'semantic-ui-react';
import { Padding, H1, H3Light, H3, P, PaddingBackArrow } from './Styles';
import AddendumQuestions from '../AddendumQuestions';
// import BackButton from "../../../assets/arrow_back-24px.svg";

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const enterKey = 'Enter';

export default (props) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const handleLetsGoClick = () => {
    setOpen(true);
  };
  const history = useHistory();
  const handleBackButton = () => {
    history.push('./');
  };
  const handleBackButtonKeyPress = (event) => {
    if (event.key === enterKey) {
      history.push('./');
    }
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <PaddingBackArrow>
          <a
            style={{ display: 'inline-block' }}
            aria-label="back to home button"
            role="link"
            tabIndex={0}
            onKeyDown={handleBackButtonKeyPress}
          >
            <Image
              onClick={handleBackButton}
              alt="back button to main page"
              size="small"
              src="assets/arrow_back-24px.svg"
              style={{ height: '32px', width: '32px', cursor: 'pointer' }}
              aria-label="back to home button"
            ></Image>
          </a>
        </PaddingBackArrow>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <PaddingBackArrow>
          <a
            onClick={handleBackButton}
            alt="link to main page"
            style={{ display: 'inline-block' }}
            aria-label="back to home button"
          >
            <Image
              alt="back button to main page"
              size="small"
              src="assets/arrow_back-24px.svg"
              style={{ height: '32px', width: '32px', cursor: 'pointer' }}
            ></Image>
          </a>
        </PaddingBackArrow>
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <PaddingBackArrow>
          <a
            onClick={handleBackButton}
            alt="link to main page"
            style={{ display: 'inline-block' }}
            aria-label="back to home button"
          >
            <Image
              alt="back button to main page"
              size="small"
              src="assets/arrow_back-24px.svg"
              style={{ height: '24px', width: '23px', cursor: 'pointer' }}
            ></Image>
          </a>
        </PaddingBackArrow>
      </Responsive>
    </Container>
  );
};
