import React, { Fragment } from 'react';
import MainFooter from './MainFooter';
import SubFooter from './SubFooter';

export default (props) => {
  return (
    <Fragment>
      <MainFooter />
      <SubFooter />
    </Fragment>
  );
};
