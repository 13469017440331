import React from 'react';
import styled from 'styled-components';
import Welcome from './Welcome';
import Question from './Question';
import Result from './Result';
import { withStore } from '../../store';

const WhiteContainer = styled.div`
  max-width: 100%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media only screen and (min-width: 768px) {
    max-width: 690px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
`;
const ComponentContainer = styled.div`
  max-width: 332px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 28px;
  @media only screen and (min-width: 768px) {
    max-width: 389px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 600px;
  }
`;
const GrayContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  padding-bottom: 0px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 992px) {
    padding-bottom: 50px;
  }
`;

const Game = (props) => {
  const current_game_state = props.store.current_game_state;

  return (
    <GrayContainer>
      <WhiteContainer>
        {!current_game_state ? (
          <ComponentContainer style={{ paddingBottom: '150px' }}>
            <Welcome />
          </ComponentContainer>
        ) : current_game_state <= 10 ? (
          <ComponentContainer style={{ paddingBottom: '68px' }}>
            <Question />
          </ComponentContainer>
        ) : (
          <div
            style={{
              paddingBottom: '47px',
              paddingTop: '28px',
              paddingLeft: '17px',
              paddingRight: '17px',
            }}>
            <Result />
          </div>
        )}
      </WhiteContainer>
    </GrayContainer>
  );
};

export default withStore(Game);
