import React, { Fragment, useEffect } from 'react';
import NavMenu from '../BeginPath/NavMenu';
import Game from './Game';

export default (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <NavMenu location={props.location.pathname}></NavMenu>
      <Game />
    </Fragment>
  );
};
