import React, { Fragment } from 'react';
import MainHeader from './MainHeader';
import NavBar from './NavBar';

export default (props) => {
  return (
    <Fragment>
      <MainHeader />
      <NavBar />
    </Fragment>
  );
};
