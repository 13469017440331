import styled from 'styled-components';
import { Button, Label } from 'semantic-ui-react';

export const Text = styled.div`
  font-family: Nunito !important;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 24px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 24px;
  }
`;
export const H4 = styled.div`
  font-family: Nunito !important;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 30px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 30px;
  }
`;

export const H5 = styled.div`
  color: #39536c;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 19px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
`;
export const LargeButtonText = styled.div`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 33px;
  font-family: Poppins !important;
`;

export const CloseButton = styled(Button)`
  color: black !important;
  background: white !important;
  margin-right: 20px !important;
  margin-top: 20px !important;
  padding: 0px !important;
`;
export const LogoDiv = styled.div`
  color: black !important;
  background: white !important;
  margin-right: 20px !important;
  margin-top: 20px !important;
  padding: 0px !important;
  display: inline-block;
`;

export const Container = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (min-width: 768px) {
    padding-left: 39px;
    padding-right: 39px;
  }
  @media only screen and (min-width: 992px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;
export const TealButton = styled(Button)`
  background-color: #007c91 !important;
  width: 390px;
  &:focus {
    background-color: #016374 !important;
    color: white !important;
  }
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;
export const TealLink = styled.a`
  color: #007c91 !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none !important;
  }
`;

export const Suggestion = styled(Label)`
  border: 0.2px solid #bdbdbd !important;
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
`;

export const GrayButton = styled(Button)`
  width: 390px;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  margin-bottom: 5px !important;
  text-align: left !important;
  // &:focus {
  //   border: 2.5px solid #39536c !important;
  // }
`;
