import React, { Fragment, useEffect } from 'react';

import NavMenu from '../BeginPath/NavMenu';
import { withStore } from '../../store';
import { useHistory } from 'react-router-dom';
import WelcomePage from './WelcomePage';
import ActionPlan1 from './Action1';
import ActionPlan2 from './Action2';
import ActionPlan3 from './Action3';
import ActionPlan4 from './Action4';
import ActionPlan5 from './Action5';
import ActionPlan6 from './Action6';
import ActionPlan7 from './Action7';
import ActionPlan8 from './Action8';

const PlanMyPath = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  // const answer1 = props.store.answer1;
  // const answer2 = props.store.answer2;
  // const answer3 = props.store.answer3;
  const currentActionPlan = props.store.currentActionPlan;
  const error = props.store.error;
 
  return (

    <Fragment>
 
      <NavMenu location={props.location.pathname}></NavMenu>
     
      {!currentActionPlan ? <WelcomePage /> : ''}
      {currentActionPlan == '1' ? <ActionPlan1 /> : ''}
      {currentActionPlan == '2' ? <ActionPlan2 /> : ''}
      {currentActionPlan == '3' ? <ActionPlan3 /> : ''}
      {currentActionPlan == '4' ? <ActionPlan4 /> : ''}
      {currentActionPlan == '5' ? <ActionPlan5 /> : ''}
      {currentActionPlan == '6' ? <ActionPlan6 /> : ''}
      {currentActionPlan == '7' ? <ActionPlan7 /> : ''}
      {currentActionPlan == '8' ? <ActionPlan8 /> : ''}
    </Fragment>
  );
};
export default withStore(PlanMyPath);
