import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import {
  H4,
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  AP2Content,
  APContent,
  EnterNameText,
  ClickableCards,
  ItalicSubtext,
  InputTextArea,
  ClickableCardSelected,
  InnerButtonDiv,
  AP2ButtonContainer,
  SuccessMessage,
  SucessTextBox,
  SuccessTitle,
  TipText,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
const IconImage = styled(Image)`
  width: 30px !important;
  height: 30px !important;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`;
const CheckIcon = styled(Image)`
  width: 24px;
  height: 24px;
  display: inline !important;
  margin-top: 6px !important;
`;
const CustomButton = styled(Button)`
  margin-top: 35px !important;
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding: 0 !important;
  color: white !important;
  text-align: center;
  line-height: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

class ActionPlan2 extends Component {
  constructor(props) {
    super(props);
    //this.handleExpandClick = this.handleExpandClick.bind(this);
    //this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.state = {
      otherText: '',
      error: false,
      isExpanded: false,
      selectedItems: [],

      somethingSelected: false,
      payloadBox: '',
    };

    if (this.props.store.AP2Items) {
      this.props.store.AP2Items.forEach((e) => {
        if (e.isSelected) {
          this.state.selectedItems.push(e.indexNum);
        }
      });
    }
    if (this.props.store.AP2OtherText != undefined) {
      this.state.otherText = this.props.store.AP2OtherText;
    }
    window.scrollTo(0, 0);
  }
  render() {
    let blank = '';
    // this.props.store.set("AP2OtherText", this.state.otherText);
    // const error = props.store.error;
    // const [OtherText, setOther] = useState("");
    // const [selectedItems, setItems] = useState([]);

    const handleButtonClick = (parent) => {
      //add in code to push other
      let blank = '';

      if (this.props.store.AP2OtherText == undefined) {
        this.props.store.set('AP2OtherText', blank);
      }
      let selectedItems = parent.state.selectedItems;

      items.forEach((e) => {
        var checked = false;
        selectedItems.forEach((i) => {
          if (e.indexNum == i) {
            checked = true;
          }
        });
        e.isSelected = checked;
      });
      if (
        selectedItems.length > 0 ||
        parent.props.store.AP2OtherText.length > 0
      ) {
        parent.props.store.set('AP2Items', items);
        // parent.props.store.set("AP2OtherText", parent.state.otherText);
        parent.props.store.set('errorAP2', false);
        parent.props.store.set('currentActionPlan', '3');
      } else {
        parent.props.store.set('errorAP2', true);
      }
    };
    const handleBackClick = (parent) => {
      parent.props.store.set('currentActionPlan', '1'); 
    };
    const handleInputText = (val) => {
      if (val.target.value.length > 0) {
        this.state.somethingSelected = true;
        this.props.store.set('errorAP2', false);
      }
      this.props.store.set('AP2OtherText', val.target.value);
      //  setOther(val.target.value);
    };

    const handleCardClick = (item, index, parent) => {
      parent.props.store.set('errorAP2', false);

      let selectedItems = parent.state.selectedItems;
      if (selectedItems.indexOf(item.indexNum) >= 0) {
        selectedItems.splice(selectedItems.indexOf(item.indexNum), 1);
      } else {
        selectedItems.push(item.indexNum);
      }
      if (selectedItems.length > 0) {
        parent.state.somethingSelected = true;
      } else {
        parent.state.somethingSelected = false;
      }

      parent.setState(selectedItems);
    };
    let items = [];
    let allItems = [];

    if (false) {
    } else {
      items = [
        {
          text: 'Lose weight',
          iconSrc: 'assets/step2_lose weight.svg',
          isSelected: false,
          indexNum: 1,
        },
        {
          text: 'Stay healthy for my family',
          iconSrc: 'assets/step2_stay healthy for my family.svg',
          isSelected: false,
          indexNum: 2,
        },
        {
          text: 'Feel more energized',
          iconSrc: 'assets/step2_feel more energized.svg',
          isSelected: false,
          indexNum: 3,
        },
        {
          text: 'Be more active',
          iconSrc: 'assets/step2_be more active.svg',
          isSelected: false,
          indexNum: 4,
        },
        {
          text: 'Take less medicine (such as high blood pressure medicine)',
          iconSrc: 'assets/step2_take less medicine.svg',
          isSelected: false,
          indexNum: 5,
        },
        {
          text: "Avoid type 2 diabetes because I've seen what it can do",
          iconSrc: 'assets/step2_avoid type 2.svg',
          isSelected: false,
          indexNum: 6,
        },
        {
          text: 'Reduce my chances of higher medical bills',
          iconSrc: 'assets/step2_reduce my chances.svg',
          isSelected: false,
          indexNum: 7,
        },
        {
          text: 'Avoid serious medical conditions',
          iconSrc: 'assets/step2_avoid serious medical conditions.svg',
          isSelected: false,
          indexNum: 8,
        },
        {
          text: 'Prioritize my health',
          iconSrc: 'assets/step2_prioritize my health.svg',
          isSelected: false,
          indexNum: 9,
        },
      ];
    }

    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '25px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>2/7</PageNumber>
                    <PageTitle>Identifying Motivators</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Congratulations on taking this step toward a healthier life!
                What is motivating you to take this step?
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I want to create a healthier life because I want to:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              display: 'block',
                              textAlign: 'right',
                              height: '30px',
                            }}
                          >
                            {' '}
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 2 other text"
                      type="text"
                      maxLength="100"
                      value={this.props.store.AP2OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Thanks for sharing!
                    </SuccessTitle>
                    <TipText>
                      The National DPP lifestyle change program is a
                      research-backed program that provides a trained lifestyle
                      coach to help you stay motivated and reach your goal of a
                      healthier life. Participants lose a minimum of 5-7% of
                      their body weight, learn habits to support their mental
                      and physical health, and, most importantly, prevent or
                      delay type 2 diabetes.
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}

              <AP2Content>
                <CustomButton onClick={() => handleButtonClick(this)}>
                  Learn About the Program
                </CustomButton>
                {this.props.store.errorAP2 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Choose a reason above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </AP2Content>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>2/7</PageNumber>
                    <PageTitle>Identifying Motivators</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Congratulations on taking this step toward a healthier life!
                What is motivating you to take this step?
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I want to create a healthier life because I want to:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          >
                            {' '}
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 2 other text"
                      type="text"
                      maxLength="100"
                      value={this.props.store.AP2OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Thanks for sharing!
                    </SuccessTitle>
                    <TipText>
                      The National DPP lifestyle change program is a
                      research-backed program that provides a trained lifestyle
                      coach to help you stay motivated and reach your goal of a
                      healthier life. Participants lose a minimum of 5-7% of
                      their body weight, learn habits to support their mental
                      and physical health, and, most importantly, prevent or
                      delay type 2 diabetes.
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}

              <AP2Content>
                <CustomButton onClick={() => handleButtonClick(this)}>
                  Learn About the Program
                </CustomButton>
                {this.props.store.errorAP2 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Choose a reason above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </AP2Content>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '40%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>2/7</PageNumber>
                    <PageTitle>Identifying Motivators</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Congratulations on taking this step toward a healthier life!
                What is motivating you to take this step?
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I want to create a healthier life because I want to:
                </EnterNameText>
                <ItalicSubtext>
                  Click on <span style={{ fontWeight: 'bolder' }}>all</span> the
                  boxes that relate to you or write in your own reason.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          >
                            {' '}
                          </span>
                          <IconImage
                            size="medium"
                            src={value.iconSrc}
                            alt="icon"
                          ></IconImage>
                          {value.text}
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>
              <AP2Content>
                <EnterNameText>Other: </EnterNameText>
                <form>
                  <fieldset>
                    <InputTextArea
                      aria-label="input step 2 other text"
                      type="text"
                      maxLength="100"
                      value={this.props.store.AP2OtherText}
                      onChange={handleInputText.bind(this)}
                    ></InputTextArea>
                  </fieldset>
                </form>
              </AP2Content>
              {this.state.somethingSelected ? (
                <SuccessMessage>
                  <SucessTextBox>
                    <Image
                      src="assets/tip icon.svg"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        marginRight: '13px',
                      }}
                    ></Image>
                    <SuccessTitle style={{ display: 'inline' }}>
                      Thanks for sharing!
                    </SuccessTitle>
                    <TipText>
                      The National DPP lifestyle change program is a
                      research-backed program that provides a trained lifestyle
                      coach to help you stay motivated and reach your goal of a
                      healthier life. Participants lose a minimum of 5-7% of
                      their body weight, learn habits to support their mental
                      and physical health, and, most importantly, prevent or
                      delay type 2 diabetes.
                    </TipText>
                  </SucessTextBox>
                </SuccessMessage>
              ) : (
                ''
              )}

              <AP2Content>
                <CustomButton onClick={() => handleButtonClick(this)}>
                  Learn About the Program
                </CustomButton>
                {this.props.store.errorAP2 ? (
                  <H5 style={{ color: '#BF2600' }}>
                    Choose a reason above before moving forward!{' '}
                  </H5>
                ) : (
                  ''
                )}
              </AP2Content>
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
      </Container>
    );
  }
}

export default withStore(ActionPlan2);
