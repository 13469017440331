import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  AP5CardTitle,
  Container,
  PageTitle,
  PageNumber,
  AP2Content,
  AP2ButtonContainer,
  ClickableCards,
  EnterNameText,
  ItalicSubtext,
  AP4ButtonContainer,
  ClickableCardFull,
  ClickableCardFullSelected,
  InnerButtonDiv,
  InputTextArea,
  CardTitle,
  CardFullTopInfo,
  CardFullSubtext,
  TipText,
  SucessTextBox,
  SuccessMessage,
  SuccessTitle,
  AP5FullRightInfo,
  ClickableCardSelected,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
const CheckIcon = styled(Image)`
  width: 24px;
  height: 24px;
  display: inline !important;
  margin-top: 6px !important;
`;
const IconImage = styled(Image)`
  width: 80px !important;
  height: 70px !important;
  vertical-align: unset !important;
  display: inline !important;
  margin-left: 15px;
  margin-right: auto;
`;
const IconImageSmall = styled(Image)`
  width: 30px !important;
  height: 30px !important;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  margin: 0 !important;
  line-height: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

class ActionPlan5 extends Component {
  constructor(props) {
    super(props);
    //this.handleExpandClick = this.handleExpandClick.bind(this);
    //this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.state = {
      error: false,

      isExpanded: false,
      selectedItems: [],
      selectedTimes: [],
      otherText: '',
      somethingSelected: false,
    };
    if (this.props.store.AP5Items) {
      this.props.store.AP5Items.forEach((e) => {
        if (e.isSelected) {
          this.state.selectedItems.push(e.indexNum);
        }
      });
    }
    if (this.props.store.AP5Times) {
      this.props.store.AP5Times.forEach((e) => {
        if (e.isSelected) {
          this.state.selectedTimes.push(e.indexNum);
        }
      });
    }
    window.scrollTo(0, 0);
  }
  render() {
    const handleButtonClick = (parent) => {
      //add in code to push other
      let selectedItems = parent.state.selectedItems;
      let selectedTimes = parent.state.selectedTimes;
      items.forEach((e) => {
        var checked = false;
        selectedItems.forEach((i) => {
          if (e.indexNum == i) {
            checked = true;
          }
        });
        e.isSelected = checked;
      });
      times.forEach((e) => {
        var checked = false;
        selectedTimes.forEach((i) => {
          if (e.indexNum == i) {
            checked = true;
          }
        });
        e.isSelected = checked;
      });
      if (selectedItems.length > 0) {
        parent.props.store.set('AP5Items', items);

        parent.props.store.set('errorAP5a', false);
      } else {
        parent.props.store.set('errorAP5a', true);
      }
      if (selectedTimes.length > 0) {
        parent.props.store.set('AP5Times', times);
        parent.props.store.set('errorAP5b', false);
      } else {
        parent.props.store.set('errorAP5b', true);
      }
      if (!parent.props.store.errorAP5a && !parent.props.store.errorAP5b) {
        parent.props.store.set('currentActionPlan', '6');
      }
    };
    const handleBackClick = (parent) => {
      parent.props.store.set('currentActionPlan', '4'); 
    };

    const handleCardClick = (item, index, parent) => {
      parent.props.store.set('errorAP5a', false);

      let selectedItems = parent.state.selectedItems;
      if (selectedItems.indexOf(item.indexNum) >= 0) {
        selectedItems.splice(selectedItems.indexOf(item.indexNum), 1);
      } else {
        selectedItems.push(item.indexNum);
      }
      if (selectedItems.length > 0) {
        parent.state.somethingSelected = true;
      } else {
        parent.state.somethingSelected = false;
      }

      parent.setState(selectedItems);
    };
    const handleCardTimeClick = (time, index, parent) => {
      parent.props.store.set('errorAP5b', false);

      let selectedTimes = parent.state.selectedTimes;
      if (selectedTimes.indexOf(time.indexNum) >= 0) {
        selectedTimes.splice(selectedTimes.indexOf(time.indexNum), 1);
      } else {
        selectedTimes.push(time.indexNum);
      }
      if (selectedTimes.length > 0) {
        parent.state.somethingSelected = true;
      } else {
        parent.state.somethingSelected = false;
      }

      parent.setState(selectedTimes);
    };
    let items = [
      {
        text: 'In person,',
        subtext: 'so I can see my coach and fellow participants directly. ',
        iconSrc: 'assets/step5_in person.svg',
        isSelected: false,
        indexNum: 1,
      },
      {
        text: 'Online,',
        subtext: 'so I can participate on my own schedule. ',
        iconSrc: 'assets/step5_online.svg',
        isSelected: false,
        indexNum: 2,
      },
      {
        text: 'Distance Learning,',
        subtext:
          'so I can participate in scheduled classes from the comfort of my home. ',
        iconSrc: 'assets/step5_distancelearning.svg',
        isSelected: false,
        indexNum: 3,
      },
    ];
    let times = [
      {
        text: 'In the morning.',
        subtext: 'I’m a morning person!',
        iconSrc: 'assets/step5_morning.svg',
        isSelected: false,
        indexNum: 1,
      },
      {
        text: 'In the afternoon.',
        subtext: 'That’s when I have the most free time!',
        iconSrc: 'assets/step5_afternoon.svg',
        isSelected: false,
        indexNum: 2,
      },
      {
        text: 'In the evening.',
        subtext: 'I have more flexibility at the end of the day.',
        iconSrc: 'assets/step5_evening.svg',
        isSelected: false,
        indexNum: 3,
      },
      {
        text: 'On the weekend.',
        subtext: 'I know I’ll have time. ',
        iconSrc: 'assets/step5_weekend.svg',
        isSelected: false,
        indexNum: 4,
      },
    ];
    return (
      <Container>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        aria-hidden="false"
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        aria-hidden="false"
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>5/7</PageNumber>
                    <PageTitle>Class Preferences</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Great work. Just a few more questions and your plan will be
                finished! Let’s look at how the program and class schedule could
                fit into your life.
                <br></br>
                <br></br>
                Many providers offer the program, so you can find a class that
                works best for you. These providers must meet CDC standards for
                recognition and use CDC-approved materials for classes.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I would prefer to participate in classes:
                </EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          >
                            {' '}
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="small image"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>I would want to go to a class:</EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer>
                {times.map((value, index) => {
                  if (this.state.selectedTimes.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle> {value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle>{value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>

              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Date
              </CustomButton>
              {this.props.store.errorAP5a || this.props.store.errorAP5b ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose both a class type and class time before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '30px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '48%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>5/7</PageNumber>
                    <PageTitle>Class Preferences</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Great work. Just a few more questions and your plan will be
                finished! Let’s look at how the program and class schedule could
                fit into your life.
                <br></br>
                <br></br>
                Many providers offer the program, so you can find a class that
                works best for you. These providers must meet CDC standards for
                recognition and use CDC-approved materials for classes.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I would prefer to participate in classes:
                </EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="small image"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>I would want to go to a class:</EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer style={{ maxWidth: '530px' }}>
                {times.map((value, index) => {
                  if (this.state.selectedTimes.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle> {value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle>{value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>

              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Date
              </CustomButton>
              {this.props.store.errorAP5a || this.props.store.errorAP5b ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose both a class type and class time before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <GrayContainer>
            <WhiteContainer>
              <Grid columns={3} divided inverted style={{ margin: '0' }}>
                <Grid.Row style={{ padding: '0 0 14 0' }}>
                  <Grid.Column
                    style={{
                      width: '10%',
                      margin: '0',

                      paddingLeft: '15px',
                      textAlign: 'left',
                    }}
                  >
                    <Icon.Group
                      size="large"
                      style={{
                        paddingTop: '7px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <Icon
                        size="large"
                        name="circle"
                        style={{
                          color: '#F5F5F5',
                          fontSize: '25px',
                        }}
                      />
                      <Icon
                        style={{
                          paddingTop: '7px',
                          top: '50%',
                          left: '40%',
                        }}
                      >
                        <form>
                          <fieldset>
                          <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                          </fieldset>
                        </form>
                      </Icon>
                    </Icon.Group>
                  </Grid.Column>
                  <Grid.Column style={{ width: '80%' }}>
                    <PageNumber>5/7</PageNumber>
                    <PageTitle>Class Preferences</PageTitle>
                  </Grid.Column>
                  <Grid.Column style={{ width: '10%' }} />
                </Grid.Row>
              </Grid>
              <BlueTextContainer>
                Great work. Just a few more questions and your plan will be
                finished! Let’s look at how the program and class schedule could
                fit into your life.
                <br></br>
                <br></br>
                Many providers offer the program, so you can find a class that
                works best for you. These providers must meet CDC standards for
                recognition and use CDC-approved materials for classes.
              </BlueTextContainer>
              <AP2Content>
                <EnterNameText>
                  I would prefer to participate in classes:
                </EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP4ButtonContainer>
                {items.map((value, index) => {
                  if (this.state.selectedItems.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardFullSelected
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="icon"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFullSelected>
                    );
                  } else {
                    return (
                      <ClickableCardFull
                        onClick={() => handleCardClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <CardFullTopInfo>
                            <IconImage
                              size="medium"
                              src={value.iconSrc}
                              alt="small image"
                            ></IconImage>
                            <AP5FullRightInfo>
                              <AP5CardTitle> {value.text}</AP5CardTitle>
                              <span style={{ display: 'block' }}>
                                {value.subtext}
                              </span>
                            </AP5FullRightInfo>
                          </CardFullTopInfo>
                        </InnerButtonDiv>
                      </ClickableCardFull>
                    );
                  }
                })}
              </AP4ButtonContainer>
              <AP2Content>
                <EnterNameText>I would want to go to a class: </EnterNameText>
                <ItalicSubtext>
                  Click <span style={{ fontWeight: '900' }}>all</span> the boxes
                  that are right for you.
                </ItalicSubtext>
              </AP2Content>
              <AP2ButtonContainer>
                {times.map((value, index) => {
                  if (this.state.selectedTimes.indexOf(value.indexNum) >= 0) {
                    return (
                      <ClickableCardSelected
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{ textAlign: 'right', display: 'block' }}
                          >
                            {' '}
                            <CheckIcon src="assets/checkAP.svg" />
                          </span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle> {value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCardSelected>
                    );
                  } else {
                    return (
                      <ClickableCards
                        onClick={() => handleCardTimeClick(value, index, this)}
                        key={value.indexNum}
                      >
                        <InnerButtonDiv>
                          {' '}
                          <span
                            style={{
                              textAlign: 'right',
                              height: '30px',
                              display: 'block',
                            }}
                          ></span>
                          <IconImageSmall
                            size="medium"
                            src={value.iconSrc}
                            alt="image icon"
                          ></IconImageSmall>
                          <AP5CardTitle>{value.text}</AP5CardTitle>
                          <span style={{ display: 'block' }}>
                            {value.subtext}
                          </span>
                        </InnerButtonDiv>
                      </ClickableCards>
                    );
                  }
                })}
              </AP2ButtonContainer>

              <br></br>
              <br></br>
              <CustomButton onClick={() => handleButtonClick(this)}>
                Select a Date
              </CustomButton>
              {this.props.store.errorAP5a || this.props.store.errorAP5b ? (
                <H5 style={{ color: '#BF2600' }}>
                  Choose both a class type and class time before moving forward!{' '}
                </H5>
              ) : (
                ''
              )}
            </WhiteContainer>
          </GrayContainer>
        </Responsive>
      </Container>
    );
  }
}

export default withStore(ActionPlan5);
