import React, { useState } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Image, Button, Label } from 'semantic-ui-react';
import {
  ImageContainer,
  H1,
  Text,
  TextContainer,
  WhiteContainer,
  GrayContainer,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';

export const Suggestion = styled(Label)`
  border: 0.2px solid #bdbdbd !important;
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 317px;
  font-family: Nunito;
  font-size: 14px !important;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
    padding-top: 24px !important;
    width: 316px;
  }
`;
const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  line-height: 0 !important;
  text-align: center;
  margin: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    width: 272px;
  }
  @media only screen and (min-width: 992px) {
    width: 285px;
  }
`;

const IconImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-bottom: 28px;
`;
const WelcomePage = (props) => {
  const startActionPlan = () => {
    props.store.set('currentActionPlan', '1');
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <GrayContainer>
          <WhiteContainer>
            <TextContainer>
              <IconImage
                size="medium"
                src="assets/Action-Plan-Welcome-Icon.svg"
                alt="Welcome page icon"
                style={{
                  height: '50px',
                  width: '50px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '33px',
                }}
              ></IconImage>

              <H1>Welcome to Plan My Path!</H1>
              <br />

              <Text>
                Everyone’s path to preventing type 2 diabetes is unique. You can
                use this tool to create a plan that works best for you. It’s
                easy – just answer a few questions and your plan will be ready!
                <br />
                <br />
                Remember, the National Diabetes Prevention Program (National
                DPP) is here to help. The National DPP lifestyle change program
                helps people put their plans into action! Joining a program and
                attending classes can help you cut your chances of developing
                type 2 diabetes in half.
              </Text>
            </TextContainer>{' '}
            <div>
              <CustomButton inverted onClick={startActionPlan}>
                Create My Plan!
              </CustomButton>
            </div>
            <Suggestion pointing>
              The information you provide in your action plan will remain
              anonymous and is for your personal use only{' '}
            </Suggestion>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <GrayContainer>
          <WhiteContainer>
            <TextContainer>
              <IconImage
                size="medium"
                src="assets/Action-Plan-Welcome-Icon.svg"
                alt="Welcome page icon"
                style={{
                  height: '50px',
                  width: '50px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '26px',
                }}
              ></IconImage>

              <H1>Welcome to Plan My Path!</H1>
              <br />

              <Text>
                Everyone’s path to preventing type 2 diabetes is unique. You can
                use this tool to create a plan that works best for you. It’s
                easy – just answer a few questions and your plan will be ready!
                <br />
                <br />
                Remember, the National Diabetes Prevention Program (National
                DPP) is here to help. The National DPP lifestyle change program
                helps people put their plans into action! Joining a program and
                attending classes can help you cut your chances of developing
                type 2 diabetes in half.
              </Text>
            </TextContainer>{' '}
            <div>
              <CustomButton inverted onClick={startActionPlan}>
                Create My Plan!
              </CustomButton>
            </div>
            <Suggestion pointing>
              The information you provide in your action plan will remain
              anonymous and is for your personal use only{' '}
            </Suggestion>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <GrayContainer>
          <WhiteContainer>
            <TextContainer>
              <IconImage
                size="medium"
                src="assets/Action-Plan-Welcome-Icon.svg"
                alt="Welcome page icon"
                style={{
                  height: '50px',
                  width: '50px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></IconImage>

              <H1>Welcome to Plan My Path!</H1>
              <br />

              <Text>
                Everyone’s path to preventing type 2 diabetes is unique. You can
                use this tool to create a plan that works best for you. It’s
                easy – just answer a few questions and your plan will be ready!
                <br />
                <br />
                Remember, the National Diabetes Prevention Program (National
                DPP) is here to help. The National DPP lifestyle change program
                helps people put their plans into action! Joining a program and
                attending classes can help you cut your chances of developing
                type 2 diabetes in half.
              </Text>
            </TextContainer>{' '}
            <CustomButton inverted onClick={startActionPlan}>
              Create My Plan!
            </CustomButton>
            <Suggestion pointing>
              The information you provide in your action plan will remain
              anonymous and is for your personal use only{' '}
            </Suggestion>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
    </Container>
  );
};
export default withStore(WelcomePage);
