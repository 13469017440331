import React, { useState } from 'react';
import styled from 'styled-components';
import { Placeholder, Button, Responsive } from 'semantic-ui-react';
import { Padding, H1About, H3Light, H3, P } from './Styles';
import AddendumQuestions from '../AddendumQuestions';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
// const CustomButton = styled(Button)`
//   background: #007c91 !important;

//   font-family: Poppins !important;
//   font-size: 22px !important;

//   box-sizing: border-box;
//   height: 54px;
//   width: 353px;
//   border: 3px solid #ffffff;
//   border-radius: 2px;
// `;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  box-sizing: border-box;
  height: 54px;
  width: 353px;
  border: 3px solid #ffffff;
  border-radius: 2px;
  line-height: 0 !important;
  font-weight: 100 !important:
  font-family: Poppins !important;
  width: 390px;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;
export default (props) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const handleLetsGoClick = () => {
    setOpen(true);
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <Padding style={{ backgroundColor: '#007c91', paddingBottom: '53px' }}>
          <H1About>What is Path 2 Prevention?</H1About>
          <P
            style={{
              paddingTop: '19px',
              color: '#FFF',
              maxWidth: '1110px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            Path 2 Prevention is your online resource from the Centers for
            Disease Control and Prevention (CDC) to learn more about
            prediabetes, type 2 diabetes, and the National Diabetes Prevention
            Program (National DPP). The site provides you with interactive
            games, activities, and information based on where you are now, to
            guide you on your path towards a healthier life.
          </P>

          <br />
          <Responsive
            minWidth={Responsive.onlyComputer.minWidth}
            onClick={handleLetsGoClick}
            as={CustomButton}
            size="massive"
            inverted
            aria-label="start path to prevention">
            Get Started Now!
          </Responsive>
        </Padding>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <Padding style={{ backgroundColor: '#007c91', paddingBottom: '43px' }}>
          <H1About style={{ color: '#FFF' }}>
            What is Path 2 Prevention?
          </H1About>
          <P>
            Path 2 Prevention is your online resource from the Centers for
            Disease Control and Prevention (CDC) to learn more about
            prediabetes, type 2 diabetes, and the National Diabetes Prevention
            Program (National DPP). The site provides you with interactive
            games, activities, and information based on where you are now, to
            guide you on your path towards a healthier life.
          </P>

          <br />
          <Responsive
            {...Responsive.onlyTablet}
            onClick={handleLetsGoClick}
            as={CustomButton}
            size="massive"
            inverted>
            Get Started Now!
          </Responsive>
        </Padding>
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <Padding style={{ backgroundColor: '#007c91', paddingBottom: '30px' }}>
          <H1About>What is Path 2 Prevention?</H1About>
          <P>
            Path 2 Prevention is your online resource from the Centers for
            Disease Control and Prevention (CDC) to learn more about
            prediabetes, type 2 diabetes, and the National Diabetes Prevention
            Program (National DPP). The site provides you with interactive
            games, activities, and information based on where you are now, to
            guide you on your path towards a healthier life.
          </P>

          {/* <H3Light>
          Get started on your path to preventing type2 diabetes here.
        </H3Light> */}
          <br />

          <Responsive
            {...Responsive.onlyMobile}
            onClick={handleLetsGoClick}
            as={CustomButton}
            size="massive"
            inverted
            fluid>
            Get Started Now!
          </Responsive>
        </Padding>
      </Responsive>
      <AddendumQuestions open={open} close={close} />
    </Container>
  );
};
