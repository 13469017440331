import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Label } from 'semantic-ui-react';

export const Text = styled.div`
  color: #333333;
  font-family: Nunito !important;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 20px;
  }
`;
export const H1 = styled.h1`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
`;
export const H4 = styled.div`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
`;

export const H3 = styled.div`
  color: #ffffff;
  font-family: Nunito !important;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.43px;
  line-height: 41px;
  @media only screen and (min-width: 768px) {
    font-size: 30px;
    letter-spacing: 0.43px;
    line-height: 41px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 30px;
    letter-spacing: 0.43px;
    line-height: 41px;
  }
`;

export const LargeButtonText = styled.div`
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 27px;
`;
export const WhiteButton = styled(Button)`
  border: 2.5px solid #bed0e1 !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  box-shadow: 0 6px 10px 0 #e0e0e0 !important;
`;

export const TealButton = styled(Button)`
  width: 322px !important;
  border-radius: 2px !important;
  background-color: #e3f0f2 !important;
  box-shadow: 0 6px 10px 0 #e0e0e0 !important;
`;

export const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(180deg, #00b3c4 0%, #007c91 100%);
  box-shadow: 0 4px 4px 0 rgba(189, 189, 189, 0.67);
  text-align: center;
`;

export const QuestionContainer = styled.div`
  padding: 30px;
  height: 100%;
  overflow-y: auto;
`;

export const AnswerContainer = styled.div`
  padding: 21px;
  height: 100%;
  overflow-y: auto;
  &:hover {
    cursor: pointer;
  }
`;

export const SmallBoldText = styled.div`
  color: #ffffff;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
`;

export const SmallText = styled.div`
  color: #ffffff;
  font-family: Nunito;
  font-size: 16px;
  letter-spacing: 0.23px;
  line-height: 22px;
`;

export const FeedbackCorrect = styled.div`
  max-width: 151px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  background-color: rgb(54, 179, 126, 0.2);
`;

export const FeedbackInCorrect = styled.div`
  max-width: 151px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  background-color: rgb(191, 38, 0, 0.2);
`;

export const Feedback = styled.div`
  max-width: 151px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
`;
