import React, { Fragment } from 'react';
import styled from 'styled-components';

export const ImageContainer = styled.div`
  text-align: center;

  margin-right: auto;
  margin-left: auto;
  margin-top: 26px;
  padding-bottom: 13px;
`;
export const TextBlurbBox = styled.div`
  box-sizing: border-box;
  height: 85px;
  width: 317px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  background-color: #f5f5f5;
  margin-left: auto;
  margin-right: auto;
  &:after {
    content: ' ';
    display: inline-block;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;

    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(0px);
    transform: translateX(11px);
    transition: transform 0.2s ease-out;
  }
`;

export const TextBlurb = styled.p`
  margin-left: auto;
  margin-right: auto;
  width: 276px;
  color: #262525;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
  color: #262525;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
`;

export const Text = styled.div`
  color: #333333;
  font-family: Nunito !important;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 20px;
  }
`;
export const H1 = styled.h1`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
`;
export const H4 = styled.div`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 20px;
  }
`;
export const WhiteContainer = styled.div`
  max-width: 100%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 36px;
  padding-left: 0;
  padding-top: 9px;
  @media only screen and (min-width: 768px) {
    max-width: 690px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
`;
export const TextContainer = styled.div`
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 26px;
  @media only screen and (min-width: 768px) {
    max-width: 495px;
    padding-bottom: 31px;
    padding-top: 31px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 24px;
    max-width: 600px;
    padding-bottom: 30px;
    margin-bottom: 9px;
  }
`;

export const PageNumber = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

export const PageTitle = styled.h1`
  color: #222a35;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.01px;
  line-height: 27px;
  text-align: center;
  font-weight 100;
`;
export const PageTitleAP8 = styled.h1`
  color: #222a35;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.01px;
  line-height: 27px;
  text-align: center;
  font-weight: 500;
  @media only screen and (min-width: 768px) {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 35px;
    text-align: center;
  }
  @media only screen and (min-width: 992px) {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 35px;
    text-align: center;
  }
`;
export const BlueTextContainer = styled.div`
  background-color: #f2f8fd;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 12px 16px 16px 16px;
  color: #222a35;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  @media only screen and (min-width: 768px) {
    max-width: 100%;  color: #222a35;
   
    padding: 12px 60px 18px 60px;
   
  }
  @media only screen and (min-width: 992px) {
    color: #222a35;
    max-width: 100%;
    padding: 10px 160px 18px 160px;
    font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
   
`;
export const GrayContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  padding-bottom: 10px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 992px) {
    padding-bottom: 50px;
  }
`;

export const H5 = styled.div`
  color: #39536c;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 19px;
  margin-top: 18px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
`;

export const Container = styled.div`
  width: 100%;
  text-align: center;
  margin: 0;
`;
export const APContent = styled.div`
  padding-top: 24px;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 331px;
    padding-top: 44px;
  }
  @media only screen and (min-width: 992px) {
  }
`;
export const AP2Content = styled.div`
  padding-top: 24px;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 510px;
    padding-top: 18px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 785px;
  }
`;
export const AP2ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 600px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 900px;
  }
`;
export const AP4ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 600px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1110px;
  }
`;
export const EnterNameText = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.01px;
  line-height: 24px;
  text-align: left;
  padding-bottom: 7.5px;
`;
export const InputName = styled.input`
  box-sizing: border-box;
  height: 41px;
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  color: #262525;
  font-family: Nunito;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding-left: 9.5px;
`;
export const InputTextArea = styled.textarea`
  box-sizing: border-box;
  height: 41px;
  width: 100%;
  height: 99px;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  color: #262525;
  font-family: Nunito;
  font-size: 18px;
  letter-spacing: 0.26px;
  line-height: 24px;
  padding-left: 9.5px;
`;
export const ImageDiv = styled.div`
  margin: 40.5px auto 48.2px auto;
  @media only screen and (min-width: 768px) {
    margin: 33.5px auto 56px auto;
  }
  @media only screen and (min-width: 992px) {
    margin: 33.5px auto 56px auto;
  }
`;
export const ClickableCards = styled.button`
  padding-bottom: 50px;
  height: auto;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  height: 175.5px;
  width: 167.5px;
  border: 1.5px solid #bed0e1;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 6px 10px 0 #e0e0e0;
  margin: 0 5px 10px 5px;
  &:hover {
    cursor: pointer;
  }
`;
export const ClickableCardSelected = styled.button`
  padding-bottom: 50px;
  height: auto;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  height: 175.5px;
  width: 167.5px;
  border: 2.5px solid #39536c;
  border-radius: 2px;
  background-color: #fafeff;
  box-shadow: 0 6px 10px 0 #e0e0e0;
  margin: 0 5px 10px 5px;
  &:hover {
    cursor: pointer;
  }
`;
export const ClickableCardFull = styled.button`
  padding-bottom: 35px;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  width: 345px;
  border: 1.5px solid #bed0e1;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 6px 10px 0 #e0e0e0;
  margin: 0 auto 10px auto;
  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    width: 390px;
  }
  @media only screen and (min-width: 992px) {
    width: 345px;
    margin: 0 7.5px 19px 7.5px;
  }
`;
export const ClickableCardFullSelected = styled.button`
  padding-bottom: 35px;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  width: 345px;
  border: 2.5px solid #39536c;
  border-radius: 2px;
  background-color: #fafeff;
  box-shadow: 0 6px 10px 0 #e0e0e0;
  margin: 0 auto 10px auto;
  @media only screen and (min-width: 768px) {
    width: 390px;
    margin: 0 auto 12px auto;
  }
  @media only screen and (min-width: 992px) {
    width: 345px;
    margin: 0 7.5px 19px 7.5px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const InnerButtonDiv = styled.span`
  width: 100%;
  color: #262525;
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: center;
  vertical-align: top;
`;
export const ItalicSubtext = styled.p`
  text-align: left;
  color: #333333;
  font-family: Nunito;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.2px;
  line-height: 19px;
  margin-bottom: 30px !important;
`;
export const SuccessMessage = styled.div`
  color: #333333;
  width: 100%;
  background-color: rgba(54, 179, 126, 0.15);
  padding-top: 25px;
  padding-bottom: 35px;
  margin-top: 60px;
  @media only screen and (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 20px;
    margin-top: 30px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
  }
`;
export const SucessTextBox = styled.div`
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  @media only screen and (min-width: 768px) {
    max-width: 485px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 785px;
    margin-top: 8px !important;
  }
`;
export const SuccessTitle = styled.div`
  color: #333333;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
  text-align: left;
  display: inline;
`;

export const TipText = styled.div`
  color: #333333;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 19px;
  margin-top: 7px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
`;

export const ExpandingTile = styled.button`
  width: 100%;
  background-color: white;
  border: none;
  text-align: left;
  margin-bottom: 14px;
  &:hover {
    cursor: pointer;
  }
`;
export const ExpandingTileFont = styled.div`
  color: #333333;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
`;
export const AP3Line = styled.div`
  box-sizing: border-box;
  height: 1px;
  margin-bottom: 18px;
  border: 1px solid #e0e0e0;
`;
export const Chevron = styled.i`
  position: relative;
  display: inline-block;
  float: right;
  height: 10px;
  top: 8px;
  margin-right: 10px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    border: 7px solid transparent;
    top: 0;
    border-top-color: #007c91;
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    border: 7px solid transparent;
    top: -3px;
    border-top-color: #fff;
  }
`;
export const ChevronUp = styled.i`
  position: relative;
  display: inline-block;
  float: right;
  height: 10px;
  top: 4px;
  margin-right: 10px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    border: 7px solid transparent;
    top: -3px;
    border-bottom-color: #007c91;
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    border: 7px solid transparent;
    top: 0;
    border-bottom-color: #fff;
  }
`;
export const AP3Text = styled.div`
  color: #333333;
  font-family: Nunito;
  font-size: 16px;
  letter-spacing: 0.23px;
  line-height: 22px;
  text-align: left;
  margin-top: 23px;
  margin-bottom: 40px;
`;

export const SuccessTip = styled.div`
  width: 100%;
  background-color: rgba(54, 179, 126, 0.15);
  padding-top: 25px;
  padding-bottom: 35px;
  margin-top: 0px;
  margin-bottom: 38px;
  max-width: 328px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
  @media only screen and (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 20px;
    margin-top: 30px;
    max-width: 525px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    max-width: 575px;
  }
`;
export const CardTitle = styled.span`
  display: inline;
  margin-left: 12px;
  color: #262525;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
`;
export const Ap8Subtext = styled.div`
  display: inline;
  margin-left: 12px;
  color: #262525;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
`;

export const CardFullSubtext = styled.div`
  margin-left: 62px;
  margin-right: 20px;
  color: #333333;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-align: left;
`;
export const CardFullTopInfo = styled.span`
  text-align: left;
  padding-left: 22px;
  display: block;
`;
export const AP5FullRightInfo = styled.span`
  margin: auto;
  display: inline-block;
  width: 175px;
  color: #333333;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  margin-left: 25px;
`;
export const AP5CardTitle = styled.span`
  margin-left: 0px;
  color: #262525;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
  vertical-align: top;
`;

export const AP7DivBorder = styled.div`
  border: 1px solid #65b0bd;
  border-radius: 5px;
  margin: 0 6px 9px 6px;
  width: auto;
  text-align: left;
  padding-bottom: 18px;
  @media only screen and (min-width: 768px) {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 992px) {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AP7description = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin: 12px 8px 12px 8px;
  @media only screen and (min-width: 768px) {
    margin: 12px 8px 12px 28px;
  }
  @media only screen and (min-width: 992px) {
    margin: 12px 8px 12px 28px;
  }
`;

export const AP7bulletnote = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 8px;
  font-style: italic;
  @media only screen and (min-width: 768px) {
    margin-left: 28px;
    margin-right: 28px;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 28px;
    margin-right: 28px;
`;
export const AP7bullets = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 18px;
  font-weight: bolder;
  @media only screen and (min-width: 768px) {
    margin-left: 48px;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 78px;
  }
`;
export const AP7NameLine = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 18px;
  letter-spacing: 0.01px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
`;
export const EditPlanText = styled.span`
  @media print {
    visibility: hidden;
  }
  color: #007c91;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.26px;
  line-height: 25px;
  text-align: center;
  display: block;
  text-decoration: underline;
  margin-top: 15px;
  display: inline-block;
  margin-left: 5px;
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 992px) {
  }
  &:hover {
    cursor: pointer;
    text-decoration: none !important;
  }
`;

export const AP7ReadyText = styled.div`
  color: #222a35;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.01px;
  line-height: 24px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 37px;
  @media only screen and (min-width: 768px) {
    margin-top: 15px;
    margin-bottom: 54px;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 19px;
    margin-bottom: 36px;
  }
`;

export const NoPrintDiv = styled.div`
  display: block;
  @media print {
    visibility: hidden;
  }
  @media only screen and (min-width: 768px) {
    display: inline-block;
  }
  @media only screen and (min-width: 992px) {
    display: inline-block;
  }
`;
