import React, { createRef, Component } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { Sticky } from 'semantic-ui-react';
import Home from './Home';
import About from './About';
import FindAProgram from './FindAProgram';
import NotFound from './NotFound';
import Header from './Header';
import Footer from './Footer';
import BeginPath from './BeginPath';
import LearnMore from './LearnMore';
import StartPlan from './StartPlan';
import AddendumQuestionsMobile from './AddendumQuestions/AddendumContent';
import { createStore } from '../store';
// import window from 'global';

import styled from 'styled-components';

export const Skip = styled.a`
  background: #39536c;
  color: #fff;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  &:focus {
    transform: translateY(0%);
  }
`;
class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      let width = window.innerWidth;
      this.state = { width: width };
    }
  }
  contextRef = createRef();

  render() {
    return (
      <div ref={this.contextRef}>
        <Skip href="#main">Skip to content</Skip>
        <Sticky context={this.contextRef}>
          <Header />
        </Sticky>
        <div id="main">
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Home name="Home" {...props} />}
            />
            <Route
              path="/home"
              render={(props) =>
                this.state?.width < 768 ? (
                  <AddendumQuestionsMobile />
                ) : (
                  <Home name="Home" {...props} />
                )
              }
            />
            <Route exact path="/about" component={About} />
            <Route exact path="/findaprogram" component={FindAProgram} />
            <Route exact path="/beginpath" component={BeginPath} />
            <Route exact path="/learnmore" component={LearnMore} />
            <Route exact path="/startplan" component={StartPlan} />

            <Route
              exact
              path="/addendumquestions"
              component={AddendumQuestionsMobile}
            />
            <Route component={NotFound} status={404} />
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

export default createStore(App);
