import React from 'react';
import { Icon, Grid, List, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import { Padding, Link } from './Styles';

const Container = styled.div`
  background-color: #f5f5f5;
  font-size: 13px;
`;
const RightAlignedCol = styled(Grid.Column)`
  text-align: right;
`;
const CenterAlignedCol = styled(Grid.Column)`
  text-align: center;
`;
export default (props) => {
  return (
    <Container>
      <Padding>
        <Grid columns={3} stackable>
          <Responsive as={Grid.Row} minWidth={Responsive.onlyTablet.minWidth}>
            <Grid.Column>
              <Link href="https://www.hhs.gov/">
                U.S. Department of Health & Human Services
              </Link>
            </Grid.Column>
            <CenterAlignedCol>
              <Link href="https://www.usa.gov/">USA.gov</Link>
            </CenterAlignedCol>
            <RightAlignedCol>
              <Link href="https://www.cdc.gov/Other/disclaimer.html">
                CDC Website Exit Disclaimer{' '}
                <Icon name="external" size="large"></Icon>
              </Link>
            </RightAlignedCol>
          </Responsive>
          <Responsive as={Grid.Column} {...Responsive.onlyMobile}>
            <List relaxed>
              <List.Item>
                <Link href="https://www.hhs.gov/">
                  U.S. Department of Health & Human Services
                </Link>
              </List.Item>
              <List.Item>
                <Link href="https://www.usa.gov/">USA.gov</Link>
              </List.Item>
              <List.Item>
                <Link href="https://www.cdc.gov/Other/disclaimer.html">
                  CDC Website Exit Disclaimer{' '}
                  <Icon name="external" size="large"></Icon>
                </Link>
              </List.Item>
            </List>
          </Responsive>
        </Grid>
      </Padding>
    </Container>
  );
};
