import React, { Fragment } from 'react';
import styled from 'styled-components';

export const Padding = styled.div`
  @media only screen and (min-width: 768px) {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (min-width: 992px) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 31px;
    padding-right: 31px;
  }
`;

export const H4 = styled.div`
  font-family: Poppins !important;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 500;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 25px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 25px;
  }
`;

export const Link = styled.a`
  color: rgb(20, 20, 20);
  @media only screen and (min-width: 768px) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.23px;
    line-height: 25px;
  }
  @media only screen and (min-width: 992px) {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.29px;
    line-height: 30px;
  }
`;
